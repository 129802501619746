<app-header-top-sessions *ngIf="!isLogged"></app-header-top-sessions>
<app-header-top *ngIf="isLogged"></app-header-top>
<div [style.margin-top]="'14em'"
    fxFlex="100" fxLayout="column"
    fxLayout.md="column" fxLayout.sm="column" fxLayout.xs="column"
    >
    <div fxFlex="" fxLayout="column" class="container">
        <!--boton de retroceso-->
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
            <div flex="15">
                <button mat-mini-fab color="purple" focusable="false" (click)="navigateTo('dashboard/cursos')">
                    <mat-icon>arrow_back</mat-icon>
                </button>
            </div>
            <div flex="85" fxLayoutAlign="center center">
                <mat-label>Regresar a cursos</mat-label>
            </div>
        </div>
        <!--/-->
        <div fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="100" fxFlex.xs="100" fxLayout.xs="column" fxLayout="row">
                <div fxFlex="60" fxFlex.xs="100" fxLayout="column">
                    <mat-card fxLayout="column">
                        <h3 style="word-break: break-all;">{{course?.course}}</h3>
                        <mat-label class="course-description">{{course?.description}}</mat-label>
                        <!-- <label><mat-icon [inline]="true">alarm</mat-icon> Fecha de la última actualización: {{modifiedAt}}</label> -->
                    </mat-card>
                    <h3 style="margin-left: 8px;">Lo que aprenderás</h3>
                    <mat-card style="padding: 0px;">
                        <mat-accordion *ngIf="modules" [perfectScrollbar]="">
                            <mat-expansion-panel [expanded]="expanded" (opened)="panelOpenState = true"
                                                    (closed)="panelOpenState = false">
                                <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{(panelOpenState)?'Ocultar':'Ver'}} plan de estudios.
                                </mat-panel-title>
                                <mat-panel-description>
                                    
                                </mat-panel-description>
                                </mat-expansion-panel-header>
                                <!--content-->
                                <div fxLayout="row wrap" fxLayoutAlign="start start" fxFlex class="course-content">
                                    <div fxFlex="50" fxFlex.xs="100" *ngFor="let module of modules;">
                                        <div fxLayout="column" fxFlex="100" fxLayoutAlign="center start">
                                            <label style="font-weight: bold;"><mat-icon style="font-size: 10px;" [inline]="true">circle</mat-icon> {{module?.module}}</label>
                                            <div fxFlex="50" fxFlex.xs="100" *ngFor="let topic of module?.topics;" style="margin-left: 10px;">
                                                <label><mat-icon style="font-size: 10px;" [inline]="true">remove</mat-icon> {{topic?.topic}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--/-->
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div *ngIf="!modules" class="text-center" style="padding: 1em;">
                            <mat-label>No hay contenido del curso para mostrar.</mat-label>
                        </div>
                    </mat-card>
                        
                    <!-- <h3 style="margin-left: 8px;">Requisitos</h3>
                    <mat-card>
                        <mat-icon style="font-size:10px;" [inline]="true">circle</mat-icon>
                        <mat-label>
                            Nivel requerido: <b><i>{{course?.courseLevel}}</i></b>
                        </mat-label>
                    </mat-card> -->
                </div>
                <div fxLayout="column" fxFlex.xs="100" fxFlex="40">
                    <mat-card fxLayout="column">
                        <div fxLayout="column" fxLayoutAlign="space-around center" class="m-1">
                            <img 
                                style="width: 320px;height: auto;border-radius: 8px;box-shadow: lightgray 4px 4px 4px 0px;"
                                onerror="this.src='assets/images/entrepeneurs/not-found.png'" [src]="course?.imageUrl" alt="" width="auto">
                            <mat-label class="m-3 color-purple">Curso: <b>"{{course?.course}}"</b></mat-label>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="center center">
                            <h3>{{course?.salePriceLabel}}</h3>
                        </div>
                        <div fxLayout="row" *ngIf="isLogged">
                            <button *ngIf="course?.isMembership" mat-button class="btn btn-purple" fxFlex="100" (click)="buySubscription()">
                                <mat-label>Adquirir membresia anual</mat-label>
                            </button>
                            <button *ngIf="!course?.isMembership" mat-button class="btn btn-purple" fxFlex="100" (click)="buyCourse()">
                                <mat-label>Comprar ahora</mat-label>
                            </button>
                        </div>

                        <div fxLayout="row" *ngIf="!isLogged">
                            <button mat-button class="btn btn-purple" fxFlex="100" [routerLink]="['/signin']"   >
                                <mat-label>Comprar ahora</mat-label>
                            </button>
                        </div>

                        <!-- <div *ngIf=" isLogged == true && !userHasLevelrequired(course?.courseLevelId) && role.isClient()" fxLayoutAlign="center center">
                            <p style="text-align: center;">
                                <span class="text-danger"><b>Aún no puedes adquirir este Curso</b></span>, <br>porque <b>eres Nivel "{{userInfo.userLevel}}"</b> y <b>se requiere Nivel "{{course.courseLevel}}"</b>
                            </p>
                        </div> -->
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
    <app-footer fxFlex></app-footer>
</div>
