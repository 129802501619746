import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '../shared/api';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { Config } from 'protractor';
import { catchError, map } from 'rxjs/operators';
import { Location } from '@angular/common';
import { DialogConfirmComponent } from '../dialogs/dialog-confirm/dialog-confirm.component';
import { DialogSessionempComponent } from '../dialogs/dialog-sessionemp/dialog-sessionemp.component';
import { DialogCalendarinfoComponent } from '../dialogs/dialog-calendarinfo/dialog-calendarinfo.component';
import { modalMultimedia } from '../shared/classes';
import * as intro from 'intro.js';
import { DialogStoreComponent } from '../dialogs/dialog-store/dialog-store.component';
import { LayoutService } from 'app/shared/services/layout.service';
import { DateService } from './date.service';
import { DialogPrivacyComponent } from '../dialogs/dialog-privacy/dialog-privacy.component';
import { DialogTermsComponent } from '../dialogs/dialog-terms/dialog-terms.component';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public introJS = intro();
  private URL_LOGIN: string = API.URL_BASE + 'auth/login';
  private URL_LOGOUT: string = API.URL_BASE + 'auth/logout';
  private URL_CAT_COURSES: string = API.URL_BASE + 'auth/courses/getCourses';
  private URL_GET_COURSE: string = API.URL_BASE + 'auth/courses';
  private URL_SIGNUP: string = API.URL_BASE + 'auth/signup';
  private URL_CONTACTUS: string = API.URL_BASE + 'auth/contactus';
  private URL_GET_ALL_ACTIVITIES: string = API.URL_BASE + 'auth/activities/all';
  private URL_GET_CHAT_POSTS: string = API.URL_BASE + 'auth/chat';
  private URL_GET_COURSES_BY_USER: string = API.URL_BASE + 'auth/user/courses';
  private URL_GET_COURSES_NO_LOGGEDIN: string = API.URL_BASE + 'auth/getCoursesNoLoggedIn';
  private URL_GET_ACTIVITIES_BY_COURSE: string = API.URL_BASE + 'auth/activities';
  private URL_GET_PENDING_ACTIVITIES_COUNT: string = API.URL_BASE + 'auth/activities/all/pending';
  private URL_GET_PURCHASES: string = API.URL_BASE + 'auth/purchases'
  private URL_LOGIN_SOCIAL: string = API.URL_BASE + 'auth/loginSocial';
  private URL_GET_ALL_TEAMS: string = API.URL_BASE + 'auth/getTeams';
  private URL_UPDATE_ORDER_TOPICS: string = API.URL_BASE + 'auth/updateOrderTopics';
  private URL_UPDATE_ORDER_TOPIC_RESOURCES: string = API.URL_BASE + 'auth/updateOrderTopicResources';
  private URL_GET_SIMULATOR_COUNT_BY_MODULE: string = API.URL_BASE + 'auth/getSimulatorCountByModule';
  private URL_GET_PRODUCTS_TEAM: string = API.URL_BASE + 'auth/getProductsTeam';
  private URL_GET_AZUAJES_BY_TEAM = API.URL_BASE + 'auth/getAzuajesByTeam';
  private URL_BUY_AZUAJES_BY_TEAM = API.URL_BASE + 'auth/buyAzuajesByTeam';
  private URL_CREATE_CARD_PAYMENT = API.URL_BASE + 'auth/payments/card';
  private URL_CREATE_DIRECT_PAYMENT = API.URL_BASE + 'auth/payments/4x4';
  private URL_GET_AVAILABLE_USERS_BY_TOPIC = API.URL_BASE + 'auth/activities/users';
  private URL_COURSES_PAYMENTS_METHODS:string = API.URL_BASE + 'courses/payments/methods';
  private URL_PAYMENTS_STRIPE_PRODUCTS:string = API.URL_BASE + 'payments/stripe/products';
  private URL_PAYMENTS_STRIPE_PRICES:string   = API.URL_BASE + 'payments/stripe/prices';
  private URL_PAYMENTS_STRIPE_SESSIONS:string = API.URL_BASE + 'payments/stripe/sessions';
  private URL_COURSES_HOME:string = API.URL_BASE+'courses/home';
  private URL_CMS_USER_ROLE:string = API.URL_BASE+'auth/cms/users/role';
  private URL_CMS_USER_ACTIVE:string = API.URL_BASE+'auth/cms/users/active';
  private URL_CMS_USER:string = API.URL_BASE+'auth/cms/users';
  private URL_CMS_USER_RESET_PASSWORD:string = API.URL_BASE+'auth/cms/users/resetPassword';
  private URL_CMS_USER_MASS:string = API.URL_BASE+'auth/cms/users/mass';
  private URL_CMS_USER_UPDATE:string = API.URL_BASE+'auth/cms/users/update';
  private URL_CMS_INSTRUCTORS_COURSES:string = API.URL_BASE+'auth/cms/instructor/course';
  private URL_CMS_INSTRUCTORS_SET_COURSE:string = API.URL_BASE+'auth/cms/instructor/course/set';

  private URL_CMS_CREATE_COUPON:string = API.URL_BASE+'coupons/create';
  private URL_CMS_GET_COUPONS:string = API.URL_BASE+'coupons/get';
  private URL_CMS_GET_COURSES_STRIPE:string = API.URL_BASE+'coupons/courses';
  private URL_CMS_GET_LICENSEES_STRIPE:string = API.URL_BASE+'coupons/licensees';
  private URL_CMS_ACTIVATE_COUPON_STRIPE:string = API.URL_BASE+'coupons/activate';
  private URL_CMS_GET_COUPON_DETAILS:string = API.URL_BASE+'coupons/details';
  private URL_CMS_GET_PROMOTION_CODES:string = API.URL_BASE+'coupons/getPromotionCodes';
  private URL_CMS_CREATE_PROMOTION_CODE:string = API.URL_BASE+'coupons/createPromotionCode';
  private URL_CMS_ACTIVATE_PROMOTION_CODE:string = API.URL_BASE+'coupons/activePromotionCode';
  private URL_CMS_COURSES_USERS:string = API.URL_BASE+'cms/courses/users';
  //
  private headers: any = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'responseType': 'json',
  }

  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _location: Location,
    private router: Router,
    private layout: LayoutService,
    private dateService: DateService,
    private storage:StorageService
  ) { }

  //GET
  public getCmsCourseFilters(): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(`${this.URL_CMS_COURSES_USERS}/progress/filters`, {observe:'response', headers: options });
  }
  //POST
  public getCourseProgress(data: any): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(`${this.URL_CMS_COURSES_USERS}/progress`, {...data,userInfo:JSON.stringify(userInfo)}, {observe:'response', headers: options });
  }
  //GET
  public getHomeInformation(): Observable<HttpResponse<any>> {
    let headers = {...this.headers};
    return this.http.get<any>(`${this.URL_COURSES_HOME}`, { observe: 'response', headers: headers });
  }
  //GET
  public getCoursePaymentMethod(data:any): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<any>(`${this.URL_COURSES_PAYMENTS_METHODS}/${data.courseId}/${data.rcpmId}`, { observe: 'response', headers: headers });
  }
  //GET
  public getPromotionalCourses(data:any): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<any>(`${this.URL_PAYMENTS_STRIPE_PRICES}/courses/promotions/${data.page}/${data.pageSize}`, { observe: 'response', headers: headers });
  }
  //GET
  public getCourseToPay(data:any): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<any>(`${this.URL_PAYMENTS_STRIPE_SESSIONS}/courses/${data.courseId}/${data.userId}`, { observe: 'response', headers: headers });
  }
  //POST
  public stripeCreateSession(data: any): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_PAYMENTS_STRIPE_SESSIONS, {...data,userInfo:JSON.stringify(userInfo)}, {observe:'response', headers: options });
  }
  // POST: payments/stripe/sessions/renew
  public stripeCreateSessionRenew(data: any): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(`${this.URL_PAYMENTS_STRIPE_SESSIONS}/renew`, {...data,userInfo:JSON.stringify(userInfo)}, {observe:'response', headers: options });
  }
  //PUT
  public stripeActivatePrice(data: any): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.put(`${this.URL_PAYMENTS_STRIPE_PRICES}/activate`, {...data,userInfo:JSON.stringify(userInfo)}, {observe:'response', headers: options });
  }
  //POST
  public stripeCreatePrice(data: any): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_PAYMENTS_STRIPE_PRICES, {...data,userInfo:JSON.stringify(userInfo)}, {observe:'response', headers: options });
  }
  //POST
  public getCoursePrices(data:any): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<any>(`${this.URL_PAYMENTS_STRIPE_PRICES}/search`, data,{ observe: 'response', headers: headers });
  }
  //POST
  public stripeCreateProduct(data: any): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_PAYMENTS_STRIPE_PRODUCTS, {...data,userInfo:JSON.stringify(userInfo)}, {observe:'response', headers: options });
  }
  //GET
  public getCoursePaymentMethods(courseId:number): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<any>(`${this.URL_COURSES_PAYMENTS_METHODS}/${courseId}`, { observe: 'response', headers: headers });
  }
  //GET
  public getAvailableUsersByTopic(courseId:number,topicId:number): Observable<HttpResponse<Config>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GET_AVAILABLE_USERS_BY_TOPIC}/${courseId}/${topicId}`, { observe: 'response', headers: headers });
  }

  openDialogPrivacy():void {
    this.dialog.open(DialogPrivacyComponent,{
      maxWidth:'920px',maxHeight:'640px',autoFocus:false
    }).afterClosed();
  }

  openDialogTerms():void {
    this.dialog.open(DialogTermsComponent,{
      maxWidth:'920px',maxHeight:'640px',autoFocus:false
    }).afterClosed();
  }

  public createCardPayment(data:any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<Config>(`${this.URL_CREATE_CARD_PAYMENT}`, {payment:data,userInfo:JSON.stringify(userInfo)}, { headers: headers });
  }

  public createDirectPayment(data:any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<Config>(`${this.URL_CREATE_DIRECT_PAYMENT}`, {payment:data,userInfo:JSON.stringify(userInfo)}, { headers: headers });
  }

  public processHttpResponse(http: HttpErrorResponse) {
    let datetime:string = this.dateService.getCurrentDateTime();
    let message:string = '';
    if(http.error.hasOwnProperty('message')){
      message = http.error.message;
    }else if(http.error.hasOwnProperty('content')){
      message = http.error.content;
    }
    switch (http.status) {
      case 0:
        this.openDialog(`Servicio no disponible intente más tarde o verifique su conexión. ${datetime}`);
        break;
      case 404:
        this.openDialog(`Recurso o servicio no encontrado, intente de nuevo o contacte con el área de soporte. ${datetime}`);
        break;
      case 422:
        this.openDialog(`${message} ${datetime}`);
        break;
      case 500:
        this.openDialog(`Error: ${message}. ${this.dateService.getCurrentDateTime()}`);
        break;
      default:
        this.openDialog(`Error al procesar el servicio, contacte con el área de soporte. ${datetime}`);
        break;
    }
  }

  public getSimulatorCountByModule(module: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<Config>(`${this.URL_GET_SIMULATOR_COUNT_BY_MODULE}`, { ...module }, { headers: headers });
  }

  //POST
  public updateOrderTopicResources(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_UPDATE_ORDER_TOPIC_RESOURCES, data, { headers: options });
  }

  sortById(array: any[]): any[] {
    array.sort((a, b) => (Number(a.id) > Number(b.id)) ? 1 : -1);
    return array;
  }

  sortByOrder(array: any[]): any[] {
    array.sort((a, b) => (Number(a.order) > Number(b.order)) ? 1 : -1);
    return array;
  }

  //POST
  public updateOrderTopics(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_UPDATE_ORDER_TOPICS, data, { headers: options });
  }

  //POST
  public loginSocial(data: any) {
    let options = {
      headers: this.headers
    };
    return this.http.post(this.URL_LOGIN_SOCIAL, { ...data }, options);
  }

  //POST
  public login(data: any) {
    let options = {
      headers: this.headers
    };
    return this.http.post(this.URL_LOGIN, { ...data }, options);
  }

  //GET
  public logout(): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<any>(this.URL_LOGOUT, { observe: 'response', headers: headers });
  }

  //GET
  public getCourses() {
    let headers = {
      ...this.headers
    };
    return this.http.get<Config>(this.URL_CAT_COURSES, { observe: 'response', headers: headers });
  }

  //Course by ID
  //GET
  public getCourse(course_id) {
    let headers = {
      ...this.headers
    };
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let params = null;
    if(userInfo?.id){
      params = { userId: userInfo?.id };
    }
    return this.http.get<Config>(`${this.URL_GET_COURSE}/${course_id}`, { observe: 'response', headers: headers, params: params });
  }

  private URL_GET_COURSE_BY_USER: string = API.URL_BASE + 'auth/courses';
  public getCourseByUser(courseId) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GET_COURSE_BY_USER}/${courseId}/${userInfo.id}`, { observe: 'response', headers: headers });
  }

  //POST
  public signup(data: any) {
    let headers = this.headers;
    headers.from = API.URL_BASE;
    headers.frontUrl = API.URL_BASE_FRONT;
    let options = {
      headers: headers,
    };
    return this.http.post(this.URL_SIGNUP, { ...data }, options);
  }

  public contactus(data: any): Observable<HttpResponse<any>> {
    let headers = this.headers;
    headers.from = API.URL_BASE;
    headers.frontUrl = API.URL_BASE_FRONT;
    let options = {
      headers: headers,
    };
    return this.http.post<any>(this.URL_CONTACTUS, { ...data }, { observe: 'response', headers: headers});
  }

  //GET
  public getAllActivities(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(this.URL_GET_ALL_ACTIVITIES, { observe: 'response', headers: headers, params: data });
  }

  //GET
  public getChatPosts(page) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    //console.log('url',`${this.URL_GET_CHAT_POSTS}/${userInfo.id}/${page}`);
    return this.http.get<Config>(`${this.URL_GET_CHAT_POSTS}/${userInfo.id}/${page}`, { observe: 'response', headers: headers });
  }

  //GET
  public getCoursesByUser(params:any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GET_COURSES_BY_USER}`, { observe: 'response', headers: headers, params: params });
  }

   //Función para registrar el tema visto
  public async registerTopicSeen(topic:any,course:any){
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers_ = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    let data = {
      user_id : userInfo.id,
      topic_id : topic.id,
      simulator: topic.simulator,
      lastTopic: topic.lastTopic,
      courseLevelId: course.courseLevelId,
      userLevelId:userInfo.userLevelId
    };
    return this.http.post(API.URL_BASE+"auth/registerTopicSeen",data,{headers: headers_}).toPromise();
  }

  //GET
  public getCoursesNoLoggedIn(params:any) {
    //let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers
    };
    return this.http.get(`${this.URL_GET_COURSES_NO_LOGGEDIN}`, { headers: headers, params: params });
  }

  //GET
  public getActivitiesByCourse(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GET_ACTIVITIES_BY_COURSE}/${data.courseId}/${data.userId}`, { observe: 'response', headers: headers });
  }

  //GET
  public getPendingActivities(userId: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GET_PENDING_ACTIVITIES_COUNT}/${userId}`, { observe: 'response', headers: headers });
  }

  //GET
  public getPurchases(userId: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GET_PURCHASES}/${userId}`, { observe: 'response', headers: headers });
  }

  public getUserInfo() {
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  public openSnackBar(message: string, action: string = 'Aceptar') {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  public openDialog(content: string, title: string = 'Mensaje del sistema:', at: string = '') {
    return this.dialog.open(DialogComponent, {
      data: {
        title: title,
        content: content,
        at: at,
      },
      autoFocus: false,
      disableClose: true
    }).afterClosed();
  }


  public goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  public navigateTo(url: string) {
    this.router.navigate([url]);
  }

  public clearUserInfo() {
    this.storage.cleanUserInfo();
    localStorage.removeItem('userInfo');
    localStorage.removeItem('__paypal_storage__');
    localStorage.removeItem('authToken');
    localStorage.removeItem('infoUser');
  }

  public issetUserInfo() {
    try {
      let user = JSON.parse(localStorage.getItem('userInfo'));
      return (user != undefined && user != null) ? true : false;
    } catch (error) {
      return false;
    }
  }

  /* seccion crud completo de de cat courses*/
  private URL_GETCATCOURSALL: string = API.URL_BASE + 'auth/getCoursesAll';
  private URL_POSTDELETECATCOURSE: string = API.URL_BASE + 'auth/eliminarCatCourses';
  private URL_GETCOURSELEVELS: string = API.URL_BASE + 'auth/courseLevels';
  private URL_POSTACTUALIZARCATCOURSE: string = API.URL_BASE + 'auth/actualizarCatCourses';
  private URL_POSTNEWCATCOURSE: string = API.URL_BASE + 'auth/crearCatCourses';
  //GET
  public getCoursesAll(): Observable<HttpResponse<Config>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));

    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETCATCOURSALL}/${userInfo.id}/${userInfo.roleId}`, { observe: 'response', headers: headers });
  }
  //POST
  public deleteCatCourse(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTDELETECATCOURSE, JSON.stringify(data), options);
  }
  //GET
  public getCourseLevels(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(this.URL_GETCOURSELEVELS, { observe: 'response', headers: headers, params: data });
  }
  //POST
  public saveEditCatCourse(data) {
    /*let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };*/
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POSTACTUALIZARCATCOURSE, data, { headers: options });

    // return this.http.post(this.URL_POSTACTUALIZARCATCOURSE, JSON.stringify(data), options);
  }
  //POST
  public saveCatCourse(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POSTNEWCATCOURSE, data, { headers: options });
  }
  /* fin seccion crud completo de de cat courses*/

  /* perfil del usuario */
  private URL_UPDATE_USER: string = API.URL_BASE + 'auth/user/updateUser';
  public updateProfile(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_UPDATE_USER, { ...data }, { headers: options });
  }

  private URL_UPDATE_IMAGE_USER: string = API.URL_BASE + 'auth/saveImage';
  public updateImageProfile(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_UPDATE_IMAGE_USER, data, { headers: options });
  }
  /* fin perfil del usuario */
  /* seccion crud completo de de cat modules*/
  private URL_GETMODULES: string = API.URL_BASE + 'auth/getModules';
  private URL_POSTCREATEMODULE: string = API.URL_BASE + 'auth/createModule';
  private URL_POSTUPDATEMODULE: string = API.URL_BASE + 'auth/updateModule';
  private URL_POSTDELETEMODULE: string = API.URL_BASE + 'auth/deleteModule';
  //GET
  public getModelesXIdCatCurse(course_id): Observable<HttpResponse<Config>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETMODULES}/${course_id}`, { observe: 'response', headers: headers });
  }
  //POST
  public createModule(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTCREATEMODULE, JSON.stringify(data), options);
  }
  //POST
  public updateModule(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTUPDATEMODULE, JSON.stringify(data), options);
  }
  //POST
  public deleteModule(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTDELETEMODULE, JSON.stringify(data), options);
  }
  /* fin seccion crud completo de de cat modules*/
  /* seccion crud completo de de cat actividades*/
  private URL_GETACTIVITY: string = API.URL_BASE + 'auth/getActivity';
  private URL_GETTOPICS: string = API.URL_BASE + 'auth/getTopicAll';
  private URL_POSTCREATEACTIVITY: string = API.URL_BASE + 'auth/createActivivy';
  private URL_POSTUPDATEACTIVITY: string = API.URL_BASE + 'auth/updateActivivy';
  private URL_POSTDELETEACTIVITY: string = API.URL_BASE + 'auth/deleteActivivy';
  //GET
  public getActivivyXIdCatCurse(course_id): Observable<HttpResponse<Config>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETACTIVITY}/${course_id}`, { observe: 'response', headers: headers });
  }
  //POST
  public createActivivy(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTCREATEACTIVITY, JSON.stringify(data), options);
  }
  //POST
  public updateActivivy(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTUPDATEACTIVITY, JSON.stringify(data), options);
  }
  //POST
  public deleteActivivy(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTDELETEACTIVITY, JSON.stringify(data), options);
  }
  //GET
  public getTopics(courseId) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETTOPICS}/${courseId}`, { observe: 'response', headers: headers });
  }
  /* fin seccion crud completo de de cat actividades*/

  /* instructores */
  private URL_GET_INSTRUCTOR: string = API.URL_BASE + 'auth/instructors';
  public getInstructors() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_INSTRUCTOR, { headers: options, params: { state_id: userInfo.state_id, userId: userInfo.id } });
  }

  private URL_GET_INSTRUCTOR_CERTIFICATES: string = API.URL_BASE + 'auth/instructors/';
  public getInfoInstructor(id: number) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_INSTRUCTOR_CERTIFICATES + id, { headers: options });
  }

  private URL_GET_COUNTRIES: string = API.URL_BASE + 'auth/countries';
  public getCountries() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_COUNTRIES, { headers: options });
  }

  private URL_GET_STATES: string = API.URL_BASE + 'auth/states/';
  public getStates($id: number) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_STATES + $id, { headers: options });
  }
  /* fin instructores */

  /* seccion crud completo de topics*/
  private URL_GETMODULETOPICS: string = API.URL_BASE + 'auth/getTopicModule';
  private URL_GETTOPICSRESOURCES: string = API.URL_BASE + 'auth/getResourseTopicAll';
  private URL_POSTCREATETOPIC: string = API.URL_BASE + 'auth/createTopic';
  private URL_POSTUPDATETOPIC: string = API.URL_BASE + 'auth/updateTopic';
  private URL_POSTDELETETOPIC: string = API.URL_BASE + 'auth/deleteTopic';
  private URL_POSTSAVERESOURCETOPIC: string = API.URL_BASE + 'auth/saveResourseTopic';
  private URL_POSTDELETERESOURCETOPIC: string = API.URL_BASE + 'auth/deleteResourseTopic';

  //GET
  public getModuleTopics(moduloId): Observable<HttpResponse<Config>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETMODULETOPICS}/${moduloId}`, { observe: 'response', headers: headers });
  }

  //GET
  public getResourseTopicAll(idTopic): Observable<HttpResponse<Config>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETTOPICSRESOURCES}/${idTopic}`, { observe: 'response', headers: headers });
  }

  //POST
  public createTopic(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTCREATETOPIC, JSON.stringify(data), options);
  }
  //POST
  public saveResourseTopic(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POSTSAVERESOURCETOPIC, data, { headers: options });
  }
  //POST
  public deleteResourseTopic(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POSTDELETERESOURCETOPIC, data, { headers: options });
  }
  //POST
  public updateTopic(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTUPDATETOPIC, JSON.stringify(data), options);
  }
  //POST
  public deleteTopic(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTDELETETOPIC, JSON.stringify(data), options);
  }
  /* fin seccion crud completo de topics*/
  /* seccion crud completo de Teams*/
  private URL_GETTEAMS: string = API.URL_BASE + 'auth/getTeams';
  private URL_SAVETEAM: string = API.URL_BASE + 'auth/saveTeam';
  //GET
  public getTeams(course_id): Observable<HttpResponse<Config>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETTEAMS}/${course_id}`, { observe: 'response', headers: headers });
  }
  //POST
  public saveTeam(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_SAVETEAM, JSON.stringify(data), options);
  }
  /* fin seccion crud completo de Teams*/

  /* promociones usuario */
  private URL_GET_PROMOTION_BY_USER: string = API.URL_BASE + 'auth/promotions/';
  public getPromotionByUser() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_PROMOTION_BY_USER + userInfo.id, { headers: options });
  }

  private URL_GET_PURCHASE_INFO: string = API.URL_BASE + 'auth/getPurchaseInfo';
  public getPurchaseInfo(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_PURCHASE_INFO, { headers: options, params: data });
  }

  private URL_POST_BUY_COURSE: string = API.URL_BASE + 'auth/courses/buyCourse';
  public buyCourse(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_BUY_COURSE, data, { headers: options });
  }
  /* fin promociones usuario */

  private URL_POST_BUY_COURSEMENTORY: string = API.URL_BASE + 'auth/buyCourseMentoring';
  public buyCourseMentory(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_BUY_COURSEMENTORY, data, { headers: options });
  }


  fileExists(url: string): Observable<boolean> {
    //let imageFullPath = API.URL_BASE_STORAGE+url;
    return this.http.get(url)
      .pipe(
        map(response => {
          return true;
        }),
        catchError(error => {
          return of(false);
        })
      );
  }
  /* Notificaciones usuario */
  private URL_GET_NOTIFICACION_BY_USER: string = API.URL_BASE + 'auth/getNotficationXUserId/';
  private URL_CLEAR_NOTIFICACION_BY_USER: string = API.URL_BASE + 'auth/clearNotification/';
  private URL_DELETE_NOTIFICACION_BY_USER: string = API.URL_BASE + 'auth/deleteNotification/';
  public getNotficationXUserId() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_NOTIFICACION_BY_USER + userInfo.id, { headers: options });
  }
  public clearNotification() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_CLEAR_NOTIFICACION_BY_USER + userInfo.id, { headers: options });
  }

  public deleteNotification(id) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_DELETE_NOTIFICACION_BY_USER + id + '/' + userInfo.id, { headers: options });
  }
  /* fin Notificaciones usuario */

  /* seccion crud completo de de cat promotion*/
  private URL_GETCATPROMOTIONALL: string = API.URL_BASE + 'auth/getCatPromotion';
  private URL_POSTDELETECATPROMOTION: string = API.URL_BASE + 'auth/deleteCatPromotion';
  private URL_POSTUPDATECATPROMOTION: string = API.URL_BASE + 'auth/updateCatPromotion';
  private URL_POSTSAVECATPROMOTION: string = API.URL_BASE + 'auth/saveCatPromocion';
  private URL_GETALLTYPEPROMOCION: string = API.URL_BASE + 'auth/getAllTypePromotion';
  private URL_GETALLCOURSESACTIVE: string = API.URL_BASE + 'auth/getAllCourseActive';
  private URL_GETPROMOTIONUSERS: string = API.URL_BASE + 'auth/getPromocionUser';
  private URL_POSTSAVEPROMOTIONUSES: string = API.URL_BASE + 'auth/savePromocionUser';
  //GET
  public getCatPromotions(): Observable<HttpResponse<Config>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));

    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETCATPROMOTIONALL}`, { observe: 'response', headers: headers });
  }
  //POST
  public deleteCatPromotion(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTDELETECATPROMOTION, JSON.stringify(data), options);
  }
  //POST
  public updateCatPromotion(data): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POSTUPDATECATPROMOTION, {...data,userInfo:JSON.stringify(userInfo)}, { observe:'response', headers: headers });
  }
  //POST
  public saveCatPromocion(data): Observable<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POSTSAVECATPROMOTION, {...data,userInfo:JSON.stringify(userInfo)}, { observe:'response', headers: headers });
  }
  //GET
  public getAllTypePromotion() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETALLTYPEPROMOCION}`, { observe: 'response', headers: headers });
  }
  //GET
  public geetAllCourseActive() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETALLCOURSESACTIVE}`, { observe: 'response', headers: headers });
  }
  //GET
  public getCatPromotionUser(promotionId: any): Observable<HttpResponse<Config>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));

    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETPROMOTIONUSERS}/${promotionId}`, { observe: 'response', headers: headers });
  }
  //POST
  public savePromocionUser(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userInfo.access_token}`,
        'Accept': 'application/json',
        'responseType': 'json',
      }
    };
    return this.http.post(this.URL_POSTSAVEPROMOTIONUSES, JSON.stringify(data), options);
  }
  /* fin seccion crud completo de de cat promotion*/

  /* Seccion de reportes */
  private URL_REPORT_PAYMENT_HISTORIES: string = API.URL_BASE + 'getReportPaymentHistoriesXlsx';
  private URL_REPORT_COUPON_HISTORIES: string = API.URL_BASE + 'getReportCouponXlsx';
  private URL_REPORT_COURSES: string = API.URL_BASE + 'getReportCoursesXlsx';
  private URL_GET_PAYMENT_METHODS: string = API.URL_BASE + 'auth/getPaymentMethods';
  private URL_GET_COURSE_LEVEL: string = API.URL_BASE + 'auth/getCourseLevels';

  public getReportePaymentRecordsXLSX(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      'Authorization': `Bearer ${userInfo.access_token}`,
      'responseType': 'arraybuffer'
    };
    return this.http.get(this.URL_REPORT_PAYMENT_HISTORIES, { headers: headers, responseType: 'blob', params: { ...data } });
  }

  public getCouponReportXLSX(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      'Authorization': `Bearer ${userInfo.access_token}`,
      'responseType': 'arraybuffer'
    };
    return this.http.get(this.URL_REPORT_COUPON_HISTORIES, { headers: headers, responseType: 'blob', params: { ...data } });
  }

  public getReporteCoursesXLSX(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      'Authorization': `Bearer ${userInfo.access_token}`,
      'responseType': 'arraybuffer'
    };
    return this.http.get(this.URL_REPORT_COURSES, { headers: headers, responseType: 'blob', params: { ...data } });
  }

  public getPaymentMethods() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_PAYMENT_METHODS, { headers: options });
  }

  public getLevelsCourses() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_COURSE_LEVEL, { headers: options });
  }
  /* Seccion de reportes */

  /* instructores */
  private URL_GET_CALENDAR_DAY: string = API.URL_BASE + 'auth/getCalendarDay';
  public getCalendarDay(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_CALENDAR_DAY, { headers: options, params: params });
  }

  private URL_CHANGE_DAY_CALENDAR: string = API.URL_BASE + 'auth/changeDay';
  public changeDay(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_CHANGE_DAY_CALENDAR, data, { headers: options });
  }

  private URL_GET_CURSES_PURCHASED_OR_TAUGHT: string = API.URL_BASE + 'auth/getCoursesPurchasedOrTaught';
  public getCoursesPurchasedOrTaught(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_CURSES_PURCHASED_OR_TAUGHT, { headers: options, params: params });
  }

  private URL_GET_CURSES_PURCHASED_OR_TAUGHT_FOR_GAME: string = API.URL_BASE + 'auth/getCoursesPurchasedOrTaughtForGame';
  public getCoursesPurchasedOrTaughtForGame(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_CURSES_PURCHASED_OR_TAUGHT_FOR_GAME, { headers: options, params: params });
  }

  private URL_GET_MODULES_BY_CURSE_FOR_GAME: string = API.URL_BASE + 'auth/getModulesByCourseForGame';
  public getModulesByCourseForGame(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_MODULES_BY_CURSE_FOR_GAME, { headers: options, params: params });
  }
  private URL_POST_CREATE_CODE_GAME: string = API.URL_BASE + 'auth/createCodeGame';
  public createCodeGame(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_CREATE_CODE_GAME, params, { headers: options });
  }

  private URL_POST_VALIDATE_CODE_GAME: string = API.URL_BASE + 'auth/validateCodeGame';
  public validateCodeGame(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_VALIDATE_CODE_GAME, params, { headers: options });
  }

  private URL_POST_UPDATE_COLOR_USERS_GAME_SESSIONS: string = API.URL_BASE + 'auth/updateColorUsersGameSessions';
  public updateColorUsersGameSessions(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_UPDATE_COLOR_USERS_GAME_SESSIONS, params, { headers: options });
  }

  private URL_POST_CHANGE_PRODUCTS: string = API.URL_BASE + 'auth/changeProductSelected';
  public changeProductSelected(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_CHANGE_PRODUCTS, params, { headers: options });
  }

  private URL_POST_BUY_PRODUCTS: string = API.URL_BASE + 'auth/buyProductTeam';
  public buyProductSelected(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_BUY_PRODUCTS, params, { headers: options });
  }

  private URL_POST_CHANGE_ROULETTE: string = API.URL_BASE + 'auth/changeRoulette';
  public changeRoulette(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_CHANGE_ROULETTE, params, { headers: options });
  }

  private URL_POST_SAVE_GAME_SESSION: string = API.URL_BASE + 'auth/saveGameSession';
  public saveGameSession(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_SAVE_GAME_SESSION, params, { headers: options });
  }

  private URL_POST_RESET_GAME_SESSION: string = API.URL_BASE + 'auth/resetSession';
  public resetGameSession(params: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_RESET_GAME_SESSION, params, { headers: options });
  }
  /* fin instructores */
  /* seccion chat alumnos*/
  private URL_GETPUPILBYIDCOURSE: string = API.URL_BASE + 'auth/getPupilByIdCourse';
  private URL_GET_CHATALUMNO_POSTS: string = API.URL_BASE + 'auth/chatAlumno';
  private URL_POST_SAVECHAT_ALUMNO: string = API.URL_BASE + 'auth/saveChat';
  //GET
  public getPupilByIdCourse(course_id): Observable<HttpResponse<Config>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<Config>(`${this.URL_GETPUPILBYIDCOURSE}/${course_id}/${userInfo.id}/${userInfo.roleId}`, { observe: 'response', headers: headers });
  }
  //GET
  public getChatAlumnoPosts(page, idAlumno) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    //console.log('url',`${this.URL_GET_CHAT_POSTS}/${userInfo.id}/${page}`);
    return this.http.get<Config>(`${this.URL_GET_CHATALUMNO_POSTS}/${userInfo.id}/${idAlumno}/${page}`, { observe: 'response', headers: headers });
  }
  //POST
  public saveChat(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_POST_SAVECHAT_ALUMNO, data, { headers: options });
  }

  //POST updateMontos
  private URL_UPDATE_MONTOS: string = API.URL_BASE + 'auth/updateMontoGIA';
  public updateMontos(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_UPDATE_MONTOS, data, { headers: options });
  }

  //POST getProductsTeam
  public getProductsXTeam(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_GET_PRODUCTS_TEAM, data, { headers: options });
  }

  //GET
  public getTeamsAdmin() {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers_ = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };

    return this.http.get(this.URL_GET_ALL_TEAMS, { headers: headers_, observe: 'body' });
  }

  //GET
  public getAzuajesByTeam(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let headers_ = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_GET_AZUAJES_BY_TEAM, { headers: headers_, observe: 'body', params: data });
  }

  //POST
  public buyAzuajesByTeam(data) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_BUY_AZUAJES_BY_TEAM, data, { headers: options });
  }

  /* fin seccion chat alumnos*/
  goBack() {
    this._location.back();
  }

  /* Sección usuarios cms */

  // POST: cms/users/role
  public async getUserByRole(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_CMS_USER_ROLE}`,data,{observe:'response',headers:options}).toPromise();
  }

  // POST: cms/users/active
  public async activeUser(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_CMS_USER_ACTIVE}`,data,{observe:'response',headers:options}).toPromise();
  }

  // POST: cms/users/mass
  public async usersMassUpload(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_CMS_USER_MASS}`,data,{observe:'response',headers:options}).toPromise();
  }

  // POST: cms/users
  public createUser(data: any) {
    let headers = this.headers;
    headers.from = API.URL_BASE;
    headers.frontUrl = API.URL_BASE_FRONT;
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_CMS_USER, data, { headers: options });
  }

  // POST: cms/users/update
  public updateUser(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_CMS_USER_UPDATE, data, { headers: options });
  }

  // POST: cms/users/resetPassword
  public userResetPassword(data: any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post(this.URL_CMS_USER_RESET_PASSWORD, data, { headers: options });
  }

  public getUserById(userId:any) {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get(this.URL_CMS_USER + '/' + userId, { headers: options });
  }

  // POST: cms/instructor/course
  public async getInstructorCourses(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_CMS_INSTRUCTORS_COURSES}`,data,{observe:'response',headers:options}).toPromise();
  }

  // POST: cms/instructor/course/set
  public async setCourseToInstructor(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(this.URL_CMS_INSTRUCTORS_SET_COURSE,data,{observe:'response',headers:options}).toPromise();
  }

  // GET: cms/instructor/group/filters
  private URL_GET_MODULES_SIMULATOR_BY_COURSE: string = API.URL_BASE + 'auth/cms/instructor/group/filters';
  public getSimulatorsModuleByInstructorCourse(params: any):Promise<HttpResponse<any>>{
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<HttpResponse<any>>(this.URL_GET_MODULES_SIMULATOR_BY_COURSE, { headers: options, params: params }).toPromise();
  }

  // POST: cms/instructor/group
  private URL_CREATE_CODE_SESSION:string = API.URL_BASE + 'auth/cms/instructor/group';
  public async createCodeSession(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(this.URL_CREATE_CODE_SESSION,data,{observe:'response',headers:options}).toPromise();
  }
  
  // PUT: cms/instructor/group
  private URL_UPDATE_CODE_SESSION:string = API.URL_BASE + 'auth/cms/instructor/group';
  public async updateCodeSession(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.put<HttpResponse<any>>(this.URL_UPDATE_CODE_SESSION,data,{observe:'response',headers:options}).toPromise();
  }

  // GET: cms/instructor/group
  private URL_GET_REGISTRATION_CODES: string = API.URL_BASE + 'auth/cms/instructor/group';
  public getRegistrationCodes(params: any):Promise<HttpResponse<any>>{
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<HttpResponse<any>>(this.URL_GET_REGISTRATION_CODES, { observe:'response', headers: options, params: params }).toPromise();
  }
  
  // GET: instructor/group
  private URL_GET_FACILITATOR_REGISTRATION_CODES: string = API.URL_BASE + 'auth/instructor/group';
  public getListCodesByFacilitator(params: any):Promise<HttpResponse<any>>{
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<HttpResponse<any>>(this.URL_GET_FACILITATOR_REGISTRATION_CODES, { observe:'response', headers: options, params: params }).toPromise();
  }

  // PUT: instructor/group/confirm
  private URL_CONFIRM_CODE_SESSION:string = API.URL_BASE + 'auth/instructor/group/confirm';
  public async confirmCodeSession(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.put<HttpResponse<any>>(this.URL_CONFIRM_CODE_SESSION,data,{observe:'response',headers:options}).toPromise();
  }

  /* Fin sección usuarios cms */

  /* Seccion inscripcion simulador alumno */
  private URL_USER_REGISTER_TO_SIMULATOR:string = API.URL_BASE + 'auth/user/simulator/inscription';
  public async registerUserToSimulator(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(this.URL_USER_REGISTER_TO_SIMULATOR,data,{observe:'response',headers:options}).toPromise();
  }

  /* Fin seccion inscripcion simulador alumno */


  /* CUPONES */
  public async getCoupons(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(this.URL_CMS_GET_COUPONS,data,{observe:'response',headers:options}).toPromise();
  }

  public getStripeCourses(): Promise<HttpResponse<any>>  {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<HttpResponse<any>>(this.URL_CMS_GET_COURSES_STRIPE, { observe:'response', headers: options, params: null }).toPromise();
  }

  public getLicensees(): Promise<HttpResponse<any>>  {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<HttpResponse<any>>(this.URL_CMS_GET_LICENSEES_STRIPE, { observe:'response', headers: options, params: null }).toPromise();
  }

  public async createCoupon(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(this.URL_CMS_CREATE_COUPON,data,{observe:'response',headers:options}).toPromise();
  }

  public async activeCoupon(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_CMS_ACTIVATE_COUPON_STRIPE}`,data,{observe:'response',headers:options}).toPromise();
  }

  public async getCoupon(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_CMS_GET_COUPON_DETAILS}`,data,{observe:'response',headers:options}).toPromise();
  }

  public async getPromotionCodes(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_CMS_GET_PROMOTION_CODES}`,data,{observe:'response',headers:options}).toPromise();
  }

  public async createPromotionCode(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_CMS_CREATE_PROMOTION_CODE}`,data,{observe:'response',headers:options}).toPromise();
  }

  public async activePromotionCode(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    let options = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_CMS_ACTIVATE_PROMOTION_CODE}`,data,{observe:'response',headers:options}).toPromise();
  }

  /* Compra Gratuita */
  private URL_BUY_COURSE_FREE: string = API.URL_BASE + 'auth/courses/buy/free';
  public async buyCourseFree(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    data.userInfo = JSON.stringify(userInfo);
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_BUY_COURSE_FREE}`,data, { observe:'response', headers: headers }).toPromise();
  }
  /* FIN Compra Gratuita */

  /* Compra Suscripcion anual */
  private URL_VALIDATE_CUSTOMER: string = API.URL_BASE + 'subscription/stripe/customer/validate';
  public async validateCustomer(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    data.userInfo = JSON.stringify(userInfo);
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_VALIDATE_CUSTOMER}`,data, { observe:'response', headers: headers }).toPromise();
  }
  
  private URL_BUY_SUBSCRIPTION: string = API.URL_BASE + 'subscription/stripe/buy';
  public async buySubscription(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    data.userInfo = JSON.stringify(userInfo);
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_BUY_SUBSCRIPTION}`,data, { observe:'response', headers: headers }).toPromise();
  }
  
  private URL_CANCELED_SUBSCRIPTION: string = API.URL_BASE + 'subscription/stripe/cancel';
  public async canceledSubscription(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    data.userInfo = JSON.stringify(userInfo);
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.post<HttpResponse<any>>(`${this.URL_CANCELED_SUBSCRIPTION}`,data, { observe:'response', headers: headers }).toPromise();
  }
  
  private URL_DELETE_PAYMENT_METHOD: string = API.URL_BASE + 'subscription/stripe/customer/payment/method';
  public async deletePaymentMethod(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    data.userInfo = JSON.stringify(userInfo);
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.delete<HttpResponse<any>>(`${this.URL_DELETE_PAYMENT_METHOD}`, { observe:'response', headers: headers, params: data }).toPromise();
  }

  private URL_SUBSCRIPTION: string = API.URL_BASE + 'subscription';
  public async getSubscription(data:any):Promise<HttpResponse<any>> {
    let userInfo = JSON.parse(localStorage.getItem('userInfo'));
    data.userInfo = JSON.stringify(userInfo);
    let headers = {
      ...this.headers,
      'Authorization': `Bearer ${userInfo.access_token}`,
    };
    return this.http.get<HttpResponse<any>>(`${this.URL_SUBSCRIPTION}`, { observe:'response', headers: headers, params: data }).toPromise();
  }
  /* FIN Compra Gratuita */

  openConfirm(message: string) {
    return this.dialog.open(DialogConfirmComponent, {
      data: { message: message, confirm: true },
      autoFocus: false,
      disableClose: true
    }).afterClosed().toPromise();
  }

  openNewSesionDialog(message: string) {
    return this.dialog.open(DialogSessionempComponent, {
      data: { message: message },
      autoFocus: false,
      disableClose: true
    }).afterClosed().toPromise();
  }

  openCalendarInfoDialog(data: modalMultimedia) {
    return this.dialog.open(DialogCalendarinfoComponent, {
      data: data,
      autoFocus: false,
      disableClose: true,
      width: '95%',
      height: '80%',
      minWidth: '50%',
      minHeight: '50%'
    }).afterClosed().toPromise();
  }

  openProductsBoughtByTeamDialog(data) {
    return this.dialog.open(DialogStoreComponent, {
      data: data,
      autoFocus: false,
      disableClose: true,
      width: '40%',
      height: '40%',
      minWidth: '450px',
      minHeight: '400px'
    }).afterClosed().toPromise();
  }

  resetSessionGame() {
    localStorage.removeItem('isSignature');
    localStorage.removeItem('roulettePrize');
    localStorage.removeItem('currentCard');
    localStorage.removeItem('codigoAcceso');
    localStorage.removeItem('courseIdAcceso');
    localStorage.removeItem('arrayMontosXEquipo');
    localStorage.removeItem('linkMeetingZoom');
    localStorage.removeItem('moduleId');
    localStorage.removeItem('infoEquipo');
  }

  showTutorialHomeClient() {
    if ( typeof this.layout != 'undefined' &&  this.layout.isMobile) {
      return;
    }
    this.introJS = intro();
    this.introJS.setOptions({
      nextLabel: 'Siguiente',
      prevLabel: 'Anterior',
      doneLabel: 'Finalizar',
      scrollToElement: false,
      steps: [
        {
          title: 'Notificaciones',
          element: <HTMLInputElement>document.getElementById("notification"),
          intro: "Aquí podrás encontrar todos los mensajes de la plataforma."
        },
        {
          title: 'Perfil',
          element: <HTMLInputElement>document.getElementById("profile"),
          intro: "En esta sección encontrarás apartados de nuestro perfil como nivel en que nos encontramos, muro, perfil y cerrar sesión."
        },
        {
          title: 'Simulador empresarial',
          element: <HTMLInputElement>document.getElementById("button-game"),
          intro: "Aquí podrás iniciar una sesión para el simulador empresarial, donde se pondrán a prueba tus habilidades de emprendimiento y negociación."
        },
        /* {
          title: 'Inicio',
          element: <HTMLInputElement>document.getElementById("Inicio"),
          intro: "Aquí se describen los beneficios que obtendremos con la plataforma."
        }, */
        {
          title: 'Calendario',
          element: <HTMLInputElement>document.getElementById("Calendario"),
          intro: "Aquí tendremos nuestro calendario de actividades de todos los cursos que vamos adquiriendo."
        },
        {
          title: 'Cursos',
          element: <HTMLInputElement>document.getElementById("Cursos"),
          intro: "Aquí podemos comprar nuevos cursos y visualizar los adquiridos."
        },
        {
          title: 'Facilitador',
          element: <HTMLInputElement>document.getElementById("Facilitadores"),
          intro: "Aquí encontraremos información acerca de nuestros facilitadores."
        },
        {
          title: 'Facturación',
          element: <HTMLInputElement>document.getElementById("Facturación"),
          intro: "Aquí se encuentra nuestro historial de cursos adquiridos."
        }
      ]
    }).start();
  }

  showTutorialCalendarClient() {
    if (this.layout.isMobile) {
      return;
    }
    this.introJS = intro();
    this.introJS.setOptions({
      nextLabel: 'Siguiente',
      prevLabel: 'Anterior',
      doneLabel: 'Finalizar',
      tooltipClass: 'customTooltipImg',
      scrollToElement: false,
      steps: [
        {
          title: 'Calendario',
          intro: "En esta sección tendremos nuestras próximas actividades de nuestros cursos adquiridos."
        },
        {
          title: 'Indicador de actividades',
          intro: `<img src="assets/images/tour/indicador-calendario.png" width="400px" /><br><br>Dentro del calendario nos aparecerá un indicador con un número de próximas actividades.`,
        },
        {
          title: 'Actividades',
          intro: `<img src="assets/images/tour/actividades-calendario.png" width="400px" /><br><br>Al dar clic nos desplegará información más detallada de las actividades próximas.`,
        },
      ]
    }).start();
  }

  showTutorialGameClient() {
    if (this.layout.isMobile) {
      return;
    }
    this.introJS = intro();
    this.introJS.setOptions({
      nextLabel: 'Siguiente',
      prevLabel: 'Anterior',
      doneLabel: 'Finalizar',
      tooltipClass: 'customTooltipImg',
      // scrollToElement: false,
      steps: [
        {
          title: 'Dinero',
          element: <HTMLInputElement>document.getElementById("dinero-equipo"),
          intro: "Sección donde encontraremos nuestros recursos monetarios para inversión, gastos y ahorro.",
          position: 'right'
        },
        {
          title: 'Calendario',
          element: <HTMLInputElement>document.getElementById("calendar-game"),
          intro: "Sección con la semana, los días e información sobre el día.",
        },
        {
          title: 'Ciclo de negocios',
          element: <HTMLInputElement>document.getElementById("calendar-game"),
          intro: `<img src="assets/images/tour/ciclo-de-negocios.jpg" width="400px" /><br><br>Al hacer clic podemos ver el calendario con el ciclo completo de negocio.`,
          position: 'left'
        },
        {
          title: 'Opciones de la sesión',
          element: <HTMLInputElement>document.getElementById("options-calendar"),
          intro: "Sección donde encontraremos opciones extras de la sesión.",
        },
        {
          title: 'Sesión en ZOOM',
          element: <HTMLInputElement>document.getElementById("buttom-zoom"),
          intro: "Botón para entrar a una sesión de ZOOM.",
          position: 'left'
        },
        {
          title: 'Actualizar sesión',
          element: <HTMLInputElement>document.getElementById("button-update-session"),
          intro: "Botón en caso de no refrescar los días o para reconectarse a la sesión.",
          position: 'left'
        },
        {
          title: 'Cronómetro',
          element: <HTMLInputElement>document.getElementById("chronometer"),
          intro: "Aquí podrás ver tu tiempo que llevas dentro del simulador.",
          position: 'left'
        },
        {
          title: 'Area de actividades',
          // element: <HTMLInputElement>document.getElementById("activities-day"),
          intro: `<img src="assets/images/tour/game-videos.png" width="400px" /><br><br>Aquí encontraremos material audiovisual que nos instruirán en el simulador.`,
        },
        {
          title: 'Area de actividades',
          // element: <HTMLInputElement>document.getElementById("activities-day"),
          intro: `<img src="assets/images/tour/game-gastos.png" width="400px" /><br><br>Habrá días en donde podremos controlar nuestros recursos monetarios.`,
        },
        {
          title: 'Materia prima',
          element: <HTMLInputElement>document.getElementById("raw-material"),
          intro: "Sección donde veremos la materia prima comprada.",
        },
        {
          title: 'Banco',
          element: <HTMLInputElement>document.getElementById("bank"),
          intro: "Sección donde veremos nuestra deuda con el banco.",
        },
        {
          title: 'Equipo',
          element: <HTMLInputElement>document.getElementById("users-session"),
          intro: "Sección donde veremos a nuestros compañeros de nuestro equipo.",
        },
      ]
    }).start();
  }

  showTutorialCoursesClient() {
    if (this.layout) {
      if (this.layout.isMobile) {
        return;
      }
    }
    
    this.introJS = intro();
    this.introJS.setOptions({
      nextLabel: 'Siguiente',
      prevLabel: 'Anterior',
      doneLabel: 'Finalizar',
      tooltipClass: 'customTooltipImg',
      steps: [
        {
          title: 'Cursos',
          intro: "Aquí podemos adquirir nuevos cursos y ver los cursos que ya adquirimos."
        },
        {
          title: 'Cursos adquiridos',
          element: <HTMLInputElement>document.getElementById("adquired-courses"),
          intro: "Sección donde encontraremos nuestros cursos adquiridos."
        },
        {
          title: 'Cursos disponibles',
          element: <HTMLInputElement>document.getElementById("courses-available"),
          intro: "Sección donde encontraremos cursos que podemos comprar.",
        },
        {
          title: 'Comprar cursos',
          intro: `<img src="assets/images/tour/comprar-curso.png" width="400px" /><br><br>Dale clic al botón del carrito para comprar.`,
        },
        {
          title: 'Detalle del curso',
          intro: `<img src="assets/images/tour/detalle-curso.png" /><br><br>Dale clic al botón de la lupa para ver el plan de estudio y los requerimientos para llevar este curso.`,
        },
      ]
    }).start();
  }

  showTutorialInstructorsClient() {
    if (this.layout.isMobile) {
      return;
    }
    this.introJS = intro();
    this.introJS.setOptions({
      nextLabel: 'Siguiente',
      prevLabel: 'Anterior',
      doneLabel: 'Finalizar',
      tooltipClass: 'customTooltipImg',
      steps: [
        {
          title: 'Facilitadores',
          intro: "En esta sección encontraremos información sobre nuestros facilitadores.",
        },
        {
          title: 'Información',
          intro: `<img src="assets/images/tour/Tarjeta-instructor.png" width="400px" /><br><br>Si damos clic sobre la tarjeta de un facilitador desplegara información detallada sobre nuestro facilitador así como una breve descripción sobre sus expectativas e intereses, un correo de contacto, las certificaciones y capacitaciones del facilitador.`
        },
      ]
    }).start();
  }

  showTutorialBillingClient() {
    if (this.layout.isMobile) {
      return;
    }
    this.introJS = intro();
    this.introJS.setOptions({
      nextLabel: 'Siguiente',
      prevLabel: 'Anterior',
      doneLabel: 'Finalizar',
      // scrollToElement: false,
      steps: [
        {
          title: 'Información del usuario',
          element: <HTMLInputElement>document.getElementById("info-billing"),
          intro: "Sección donde encontraremos información de nuestras compras."
        },
        {
          title: 'Historial de compras',
          element: <HTMLInputElement>document.getElementById("purchase-history"),
          intro: "Sección donde encontraremos nuestro historial de cursos adquiridos así como el nombre del curso, la fecha de compra, el estado en el que se encuentra la compra."
        },
      ]
    }).start();
  }

  showTutorialDetailCourseClient() {
    if (this.layout?.isMobile == false) {
      return;
    }
    this.introJS = intro();
    this.introJS.setOptions({
      nextLabel: 'Siguiente',
      prevLabel: 'Anterior',
      doneLabel: 'Finalizar',
      scrollToElement: true,
      steps: [
        {
          title: 'Video',
          element: <HTMLInputElement>document.getElementById("course-detail-video"),
          intro: "Aquí se explicarán los temas con material audiovisual.",
          position: 'right',
        },
        {
          title: 'Material de estudio',
          element: <HTMLInputElement>document.getElementById("course-detail-study-material"),
          intro: "Aquí podrás descargar manuales y documentación para complementar el tema.",
          position: 'right',
        },
        {
          title: 'Facilitador',
          element: <HTMLInputElement>document.getElementById("course-detail-instructor"),
          intro: "Aquí encontrarás una descripción acerca del facilitador.",
          position: 'top',
        },
        {
          title: 'Descripción',
          element: <HTMLInputElement>document.getElementById("course-detail-description"),
          intro: "Aquí encontraremos una descripción acerca del curso.",
          position: 'right',
        },
        {
          title: 'Actividades',
          element: <HTMLInputElement>document.getElementById("course-detail-activities"),
          intro: "Aquí podemos ver el calendario de actividades del curso actual.",
          position: 'left'
        },
        {
          title: 'Mentoría',
          element: <HTMLInputElement>document.getElementById("course-detail-buy-mentoring"),
          intro: "Aquí podemos adquirir una mentoría en caso de requerir ayuda extra sobre el curso.",
          position: 'left'
        },
        {
          title: 'Ver compañeros',
          element: <HTMLInputElement>document.getElementById("course-detail-partner"),
          intro: "Aquí podemos ver a nuestros compañeros de curso y poder interactuar con ellos en un chat.",
          position: 'left'
        },
        {
          title: 'Módulos',
          element: <HTMLInputElement>document.getElementById("course-detail-modules"),
          intro: "Aquí podremos ver los módulos y submódulos que conforman el curso. Tome en cuenta que estos se desbloquearán una vez termine de ver el video del tema.",
          position: 'left'
        },
      ]
    }).start();
  }

  public getPrecio(monto: any) {
    return String(monto).concat(" USD");
  }

  public getColorByBackgroundColor(color:string){
    if(color == '#fff' || color == '#ffffff' || color == '#ffff00'){
      return 'black';
    }
    return 'white';
  }

  private BASE_URL:string = API.URL_BASE;
  private SUBMISSIONS_URL:string = this.BASE_URL+'submissions';
  private SUBMISSIONS_GRADES_URL:string = this.SUBMISSIONS_URL+'/grades';
  //
  getAuthHeaders():HttpHeaders {
    const userInfo = this.storage.getUserInfo();
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${userInfo.access_token}`
    });
    return headers;
  }
  /**
   * SUBMISSIONS
   */
  //GET:submissions/courses/topics/{slug}
  getTopicsByCourse(slug:string): Observable<HttpResponse<Config>> {
    return this.http.get<Config>(
      `${this.SUBMISSIONS_URL}/courses/topics/${slug}`, { observe: 'response', headers: this.getAuthHeaders() });
  }
  //POST:submissions
  storeSubmission(data:FormData): Observable<HttpResponse<Config>> {
    return this.http.post<Config>(
      `${this.SUBMISSIONS_URL}`, data, { observe: 'response', headers: this.getAuthHeaders() });
  }
  //GET:submissions/{slug}
  getSubmissionsByCourse(slug:string,statusId:number): Observable<HttpResponse<Config>> {
    return this.http.get<Config>(
      `${this.SUBMISSIONS_URL}/${slug}/${statusId}`, { observe: 'response', headers: this.getAuthHeaders() });
  }
  /**
   * SUBMISSION GRADES
   */
  //POST:submissions
  storeSubmissionGrade(data:FormData): Observable<HttpResponse<Config>> {
    return this.http.post<Config>(
      `${this.SUBMISSIONS_GRADES_URL}`, data, { observe: 'response', headers: this.getAuthHeaders() });
  }
}