<div fxLayout="column" fxFlex="100" class="p-2">
  <!--info básica del curso-->
  <div *ngIf="course != undefined" 
    fxFlex="100" fxLayoutGap="10px"
    fxLayout="row" 
    fxLayout.sm="column"
    fxLayout.xs="column"
    fxLayoutAlign="space-between center"
    class="container"
    [@animate]="{ value: '*', params: { y: '50px', delay: '100ms' } }"
    >
    <!--titulo del curso-->
    <div 
      fxLayout="column" 
      fxFlex="70" fxLayoutAlign="center start"
      fxFlex.xl="70"
      fxFlex.lg="70"
      fxFlex.md="60" 
      fxFlex.sm="60"
      fxFlex.xs="60"
      >
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div flex="5">
          <button mat-mini-fab color="primary" focusable="false" (click)="service.goBack()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <div flex="85" fxLayoutAlign="top center" style="width: 100%;margin-left: 10px;">
          <mat-label>Regresar a cursos</mat-label>
        </div>
      </div>
      <mat-label class="text-secondary">
        <!-- <mat-icon>schedule</mat-icon> Creado {{datepipe.transform(course?.createdAt, 'd/MMM/y','UTC-6','ES-MX')}} -->
      </mat-label>
    </div>
    <!--opciones disponibles-->
    <div id="course-detail-options"
      class="course-detail-options"
      fxFlex="30" fxLayoutGap="10px"
      fxFlex.xl="30"
      fxFlex.lg="30"
      fxFlex.md="40" 
      fxFlex.sm="100"
      fxFlex.xs="100"
      fxLayout="row"
      fxLayoutAlign="end center"
      >
      <ng-container *ngIf="role.isClient()">
        <mat-label>Centro de trabajo: </mat-label>
        <button type="button" mat-icon-button class="btn-purple" (click)="goToCalendar(course)" matTooltip="Ver mis actividades" matTooltipPosition="below">
          <mat-icon id="course-detail-activities">today</mat-icon>
        </button>
        <button type="button" mat-icon-button class="btn-purple" (click)="openDialogAddSubmission()"  matTooltip="Enviar trabajo" matTooltipPosition="below">
          <mat-icon>assignment</mat-icon>
        </button>
        <button type="button" mat-icon-button class="btn-purple" (click)="openDialogShowSubmissions()" matTooltip="Trabajos enviados" matTooltipPosition="below">
          <mat-icon>assignment_turned_in</mat-icon>
        </button>
      </ng-container>
    </div>
  </div>
  <!--/-->

  <!--informacion del instructor, modulos y descripcion del curso-->
  <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" class="container p-0">
    <!--informacion del curso-->
    <div 
      *ngIf="course != undefined" 
      fxFlex="50"
      fxFlex.xl="50"
      fxFlex.lg="50"
      fxFlex.md="60" 
      fxFlex.sm="100"
      fxFlex.xs="100"
      [@animate]="{ value: '*', params: { y: '50px', delay: '200ms' } }">
      <mat-card class="text-center mat-elevation-z4">
        <mat-card-header class="bg-light-gray">
          
          <mat-card-title class="text-justify title-course">{{course?.course}}</mat-card-title>
          <mat-card-title class="text-justify bg-light-gray">Video - Al terminar el video, realizar las actividades y revisar los manuales correspondientes, da click en el siguiente tema para continuar</mat-card-title>
          <!--<mat-card-subtitle class="text-left bg-light-gray">{{currentVideo?.videoName}}</mat-card-subtitle>-->
        </mat-card-header>
        <mat-card-content class="p-3" id="course-detail-video">
          <video 
            *ngIf="currentVideo" 
            [src]="currentVideo?.resourceUrl" 
            controlsList="nodownload"
            type="video/mp4" 
            style="width: 100%;height: auto; border-radius: 9px;"
            [ngStyle.xs]="'width: 100%;height: auto;'" 
            [ngStyle.sm]="'width: 100%;height: auto;'" 
            [ngStyle.md]="'width: 100%;height: auto;'" 
            [controls]="true" 
            [muted]="true" 
            [autoplay]="false"
            [loop]="false"
            oncontextmenu="return false;"
            (ended)="videoEnded(currentVideo)"
            poster="assets/images/entrepeneurs/video_placeholder.jpeg"
            >
            Tu navegador no es compatible con la etiqueta de video.
          </video>
          <mat-label class="text-justify title-course mt-3" style="width: 100%; display: inline-block;">Tema: {{selectedTopic}}</mat-label>
          <mat-label
            *ngIf="!currentVideo" 
            >
            No hay video para mostrar.
          </mat-label>
        </mat-card-content>
      </mat-card>
      
      <div>
        <!--material de estudio-->
        <mat-card *ngIf="arrayResources.length" class="text-center mat-elevation-z4" style="padding: 0;">
          <mat-card-header class="bg-light-gray">
            <mat-card-title class="text-left bg-light-gray title-course"><b>Material de Estudio</b></mat-card-title>
            <mat-card-subtitle *ngIf="selectedTopic != ''" class="text-left bg-light-gray">Tema: {{selectedTopic}}</mat-card-subtitle>
          </mat-card-header>
          <mat-card-content class="p-3" id="course-detail-study-material">
            <div class="p-3 text-justify" *ngIf="selectedTopicDescripcion && selectedTopicDescripcion != ''">
              <b>Descripción del tema:</b> {{selectedTopicDescripcion}}
            </div>
            <mat-list *ngIf="resourcesHasFiles">
              <div *ngFor="let res of arrayResources; let i = index;">
                <mat-list-item fxFlex="100" fxLayout="column" fxLayoutAlign="start start" *ngIf="!isVideo(res)">
                  <button
                    *ngIf="isImage(res)"
                    mat-button 
                    (click)="downloadFile(res)"
                    [matTooltip]="'Descargar: '+res?.fileName"
                    style="width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-left: 0px;padding-left: 0px;"
                    >
                    <mat-icon class="color-purple m-1">image</mat-icon>
                    <span>{{res.fileName}}</span>
                  </button>
                  <!---->
                  <button
                    *ngIf="isPDF(res)"
                    mat-button 
                    (click)="downloadFile(res)"
                    [matTooltip]="'Descargar: '+res?.fileName"
                    style="width: 100%;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;margin-left: 0px;padding-left: 0px;"
                    >
                    <img src="assets/images/pdf_file.png" style="width: 20px; height: 20px;" class="m-1"/>
                    <span>{{res.fileName}}</span>
                  </button>
                  <!---->
                  <button
                    *ngIf="isOfficeFile(res)"
                    mat-button 
                    (click)="downloadFile(res)"
                    [matTooltip]="'Descargar: '+res?.fileName"
                    >
                    <img [src]="getIconFile(res)" style="width: 20px; height: 20px;" />
                    {{res.fileName}}
                  </button>
                  <!---->
                </mat-list-item>
                <mat-divider></mat-divider>
              </div>
            </mat-list>
            <div *ngIf="!resourcesHasFiles" style="text-align: left;">
              <mat-label><b>Este tema no cuenta con manual del participante.</b></mat-label>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

        <!--descripción del tema-->
        <mat-card id="topic-description" class="text-center mat-elevation-z4">
        <mat-card-header class="bg-light-gray">
          <mat-card-title class="text-left bg-light-gray title-course"><b>Descripción del módulo</b></mat-card-title>
        </mat-card-header>
        <mat-card-content class="p-3">
          <p class="text-justify">
            {{(selectedModuleDescripcion && selectedModuleDescripcion != '')?selectedModuleDescripcion:'No hay descripción del módulo.'}}
          </p>
        </mat-card-content>
      </mat-card>

      <!--descripción del curso-->
      <mat-card id="course-detail-description" class="text-center mat-elevation-z4">
        <mat-card-header class="bg-light-gray">
          <mat-card-title class="text-left bg-light-gray title-course"><b>Descripción del curso</b></mat-card-title>
        </mat-card-header>
        <mat-card-content class="p-3">
          <p class="text-justify">
            {{(course?.description && course?.description != '')?course?.description:'No hay descripción del curso.'}}
          </p>
        </mat-card-content>
      </mat-card>

      <!--Instructor-->
      <mat-card id="course-detail-instructor" class="text-center mat-elevation-z4">
        <mat-card-header class="bg-light-gray">
          <!-- <div mat-card-avatar [style.background-image]="'url(' + imageUrl + ')'"
            style="background-size: cover;margin-top: 12px;margin-left: 12px; background: url('assets/images/entrepeneurs/profile.png')"></div> -->
            <img style="margin-top: 12px;margin-left: 12px;" mat-card-avatar [src]="imageUrl" onError="this.src='assets/images/entrepeneurs/profile.png';"   />
          <mat-card-title class="text-left bg-light-gray title-course"><b>Facilitador: {{course?.instructorName}}</b></mat-card-title>
          <mat-card-subtitle class="text-left bg-light-gray">{{course?.instructorTitle}}</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content class="p-3">
          <p class="text-justify">
            {{(course?.instructorDescription && course?.instructorDescription != '')?course?.instructorDescription:'No hay descripción del instructor.'}}
          </p>
        </mat-card-content>
      </mat-card>
    </div>

    <!--modulos-->
    <div 
      *ngIf="course != undefined" 
      fxFlex="50" 
      fxFlex.xl="50"
      fxFlex.lg="50"
      fxFlex.md="40" 
      fxFlex.sm="100"
      fxFlex.xs="100"
      [@animate]="{ value: '*', params: { y: '50px', delay: '300ms' } }">
      <mat-card class="text-center mat-elevation-z4" style="padding: 0;">
        <!--módulos para alumno-->
        <mat-card-content *ngIf="role.isClient()" class="p-3" id="course-detail-modules">
          <ng-container *ngIf="modules == undefined || modules.length <= 0 then showMessage else showModules">
          </ng-container>
          <ng-template #showMessage>
            <mat-label>No hay contenido del Curso para mostrar.</mat-label>
          </ng-template>
          <ng-template #showModules>
            <mat-accordion>
              <mat-expansion-panel [expanded]="(moduleIndex == currentModuleIndex)?true:false" *ngFor="let module of modules; let moduleIndex = index;" (opened)="panelOpenState = true"
                (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title style="text-align: left;">
                    {{module?.module}}
                  </mat-panel-title>
                  <!--
                  <mat-panel-description>
                    Type your name and age
                  </mat-panel-description>
                  -->
                </mat-expansion-panel-header>
                <!--Content-->
                <mat-selection-list #_modules>
                  <ng-container
                    *ngIf="module?.topics == undefined || module?.topics.length <= 0 then showMessageTopics else showTopics">
                  </ng-container>
                  <ng-template #showMessageTopics>
                    <mat-label>No hay temas para mostrar</mat-label>
                  </ng-template>
                  <ng-template #showTopics>
                    <p><b>Temas:</b></p>
                    <div
                      *ngFor="let topic of module.topics; let i = index;">
                      <mat-list-option 
                        class="rounded mt-3"
                        [id]="'topic_'+moduleIndex+'_'+i"
                        [ngClass]="(topic?.completed == 1)?'bg-light':'topic-disabled'"
                        [disabled]="(topic?.completed == 0)"
                        (click)="(topic?.completed == 1) ? showResources(topic,moduleIndex,'topic_'+moduleIndex+'_'+i): null"
                        style="text-align: left;font-size: 14px;"
                        [value]="resource"
                        [selected]="(moduleIndex == currentModuleIndex && topic.id == currentTopicId)?true:false"
                        [ngStyle]="layoutConf.isMobile ? {'height':'90px'} : {'height':'70px'} "
                        >
                          {{topic?.topic}}
                      </mat-list-option>
                      
                    </div>
                  </ng-template>
                </mat-selection-list>
              </mat-expansion-panel>

            </mat-accordion>
          </ng-template>
        </mat-card-content>
        <!--módulos para instructor-->
        <mat-card-content *ngIf="role.isInstructor()" class="p-3" id="course-detail-modules">
          <ng-container *ngIf="modules == undefined || modules.length <= 0 then showMessage else showModules">
          </ng-container>
          <ng-template #showMessage>
            <mat-label>No hay contenido del Curso para mostrar.</mat-label>
          </ng-template>
          <ng-template #showModules>
            <mat-accordion>
              <mat-expansion-panel [expanded]="(moduleIndex == currentModuleIndex)?true:false" *ngFor="let module of modules; let moduleIndex = index;" (opened)="panelOpenState = true"
                (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  <mat-panel-title style="text-align: left;">
                    {{module?.module}}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <!--Content-->
                <mat-selection-list #_modules>
                  <ng-container
                    *ngIf="module?.topics == undefined || module?.topics.length <= 0 then showMessageTopics else showTopics">
                  </ng-container>
                  <ng-template #showMessageTopics>
                    <mat-label>No hay temas para mostrar</mat-label>
                  </ng-template>
                  <ng-template #showTopics>
                    <p><b>Temas:</b></p>
                    <div
                      *ngFor="let topic of module.topics; let i = index;">
                      <mat-list-option 
                        [id]="'topic_'+moduleIndex+'_'+i"
                        (click)="showResources(topic,moduleIndex,'topic_'+moduleIndex+'_'+i)"
                        style="text-align: left;font-size: 14px;"
                        [value]="resource"
                        [selected]="(moduleIndex == currentModuleIndex && topic.id == currentTopicId)?true:false"
                        [ngStyle]="layoutConf.isMobile ? {'height':'90px'} : {'height':'70px'} "
                        >
                          {{topic?.topic}}
                      </mat-list-option>
                      <hr class="divider divider-purple"/>
                    </div>
                  </ng-template>
                </mat-selection-list>
              </mat-expansion-panel>

            </mat-accordion>
          </ng-template>
        </mat-card-content>
      </mat-card>
    </div>
  <!--/-->
  </div>
</div>