<p mat-dialog-title>{{ course?.course }}</p>
<mat-dialog-content>
  <div fxFlex="100" fxLayoutAlign="start center" fxLayout="column" fxLayoutGap="10px">
    <div fxFlex="100" fxLayoutAlign="start center">
      <mat-tab-group (selectedIndexChange)="selectedIndexChange($event)">
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="mr-1">assignment</mat-icon>
            {{ service.role.isInstructor() ? 'Recibidos' : 'Enviados' }}
          </ng-template>
          <ng-container [ngTemplateOutlet]="_submissions"></ng-container>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="mr-1">assignment_turned_in</mat-icon>
            Calificados
          </ng-template>
          <ng-container [ngTemplateOutlet]="_submissions"></ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <ng-template #_submissions>
    <ng-container *ngIf="submissions.length > 0">
      <div class="submissions-container">
        <mat-accordion [multi]="false">
          <mat-expansion-panel *ngFor="let s of submissions; let i = index;" [@animate]="{ value: '*', params: { y: '50px', delay: (i*100)+'ms' } }">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ s?.topic?.topic }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div fxFlex="100" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
              <div fxFlex="100" fxLayoutAlign="center center" fxLayoutAlign="10px">
                <div fxFlex="50" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <div fxFlex fxLayoutAlign="start center">
                    <span class="text-primary"><b>Módulo:</b></span>&nbsp;
                    <span>{{ s?.topic?.module?.module }}</span>&nbsp;
                  </div>
                  <div fxFlex fxLayoutAlign="start center" *ngIf="service.role.isInstructor()">
                    <span class="text-primary"><b>Enviada por:</b></span>&nbsp;<span>{{ s?.user?.name }}</span>
                  </div>
                  <div fxFlex fxLayoutAlign="start center">
                    <span class="text-primary"><b>Fecha de envío:</b></span>&nbsp;
                    <span>{{ s?.updated_at | date:'medium' }}</span>&nbsp;
                  </div>
                  <div fxFlex="100" fxLayoutAlign="start center">
                    <div fxFlex="50" fxLayoutAlign="start center">
                      <mat-icon class="color-green">assignment</mat-icon>&nbsp;
                      <a class="hl" [href]="s?.url" target="_blank">Ver trabajo</a>
                    </div>
                  </div>
                </div>
                <!--Trabajo por calificar-->
                <ng-container *ngIf="service.role.isInstructor() && status_id == 1">
                  <div fxFlex="50" fxLayoutAlign="start center">
                    <div fxFlex="80" fxLayoutAlign="start center">
                      <form *ngIf="getFormById(s?.id)" [formGroup]="getFormById(s?.id)">
                        <mat-form-field appearance="outline" class="full-width">
                          <mat-label>* Ingresa una calificación:</mat-label>
                          <mat-icon matPrefix>%</mat-icon>
                          <input matInput (input)="getControl(s?.id,'grade_value').markAsTouched()" formControlName="grade_value"
                          autocapitalize="off" autocomplete="off" autofocus="off" type="number" min="0" max="100" step="10"
                          (keypress)="service.onlyNumbers($event);"/>
                          <mat-hint>* Calificación en escala de 0 a 100</mat-hint>
                          <mat-error>
                            <ng-container *ngFor="let error of getFormErrorsById(s?.id)?.grade_value">
                              <ng-container *ngIf="service.formControlHasError(getControl(s?.id,'grade_value'),error)">
                                <small class="form-error">{{error.message}}</small>
                              </ng-container>
                            </ng-container>
                          </mat-error>
                        </mat-form-field>
                        <!---->
                        <mat-form-field appearance="outline" class="full-width">
                          <mat-label>Retroalimentación (opcional):</mat-label>
                          <textarea matInput formControlName="feedback" rows="2"></textarea>
                          <mat-hint>{{ getControl(s?.id,'feedback')?.value?.length }} / {{ feedbackMaxLength }}</mat-hint>
                          <mat-error>
                            <ng-container *ngFor="let error of getFormErrorsById(s?.id)?.feedback">
                              <ng-container *ngIf="service.formControlHasError(getControl(s?.id,'feedback'),error)">
                                <small class="form-error">{{error.message}}</small>
                              </ng-container>
                            </ng-container>
                          </mat-error>
                        </mat-form-field>
                      </form>
                    </div>
                    <div fxFlex="20" fxLayoutAlign="center center">
                      <button type="button" color="primary" mat-icon-button matTooltip="Guardar calificación" (click)="storeSubmissionGrade(s)" [disabled]="getFormById(s?.id)?.invalid">
                        <mat-icon>save</mat-icon>
                      </button>
                    </div>
                  </div>
                </ng-container>
                <!--Trabajo calificado-->
                <div *ngIf="status_id == 2" fxFlex="50" fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
                  <div fxFlex fxLayoutAlign="start center">
                    <span class="text-primary f-20px"><b>Calificación:</b>&nbsp;{{ s?.grade?.grade }}&nbsp;%</span>
                  </div>
                  <div fxFlex fxLayoutAlign="start center">
                    <mat-icon class="color-green" matTooltip="Calificado por:">assignment_ind</mat-icon>&nbsp;{{ s?.grade?.facilitator?.name }}&nbsp;&nbsp;<mat-icon class="color-green" matTooltip="Fecha de calificación">event</mat-icon>&nbsp;{{ s?.created_at | date:'short' }}
                  </div>
                </div>
              </div>
              <!---->
              <div *ngIf="status_id == 2" fxFlex="100" fxLayoutAlign="start center" class="text-justify">
                <mat-icon class="color-green" matTooltip="Retroalimentación">feedback</mat-icon>&nbsp;
                {{ s?.grade?.feedback ? s?.grade?.feedback : 'El facilitador(a) no agregó una retroalimentación.' }}
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-container>
    <ng-container *ngIf="submissions.length <= 0">
      <div class="submissions-container-no-content">
        No tienes trabajos enviados o por calificar.
      </div>
    </ng-container>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="center" style="overflow-x: clip;">
  <button class="btn-purple" mat-button [mat-dialog-close]="false">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_outline</mat-icon>
  </button>
</mat-dialog-actions>