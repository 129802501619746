const API = {

  //LOCAL
  //URL_BASE: 'http://localhost:8000/api/',
  //URL_BASE_STORAGE: 'http://localhost:8000',
  //URL_BASE_FRONT: 'http://localhost:4200/',//url base de angular -> al subir a servidor cambiar parámetro
  //ECHO_BROADCASTER: 'pusher',
  //ECHO_CLUSTER: 'us2',
  //ECHO_KEY: '5c2ebe96a7a06147f0d3',
  //ECHO_AUTH_END_POINT: 'http://127.0.0.1/',
  //ECHO_HOST: '127.0.0.1',
  //ECHO_WS_HOST: '127.0.0.1',
  //ECHO_WS_PORT: 6001,
  //ECHO_PORT: 6001,
  //PROD:false,

  //PRODUCCIÓN NUEVO SERVIDOR
  URL_BASE: 'https://my4x4academy.com/services/public/api/',
  URL_BASE_STORAGE: 'https://my4x4academy.com/services/public',
  URL_BASE_FRONT: 'https://my4x4academy.com',//url base de angular -> al subir a servidor cambiar parámetro
  ECHO_BROADCASTER: 'pusher',
  ECHO_CLUSTER: 'us2',
  ECHO_KEY: '5c2ebe96a7a06147f0d3',
  ECHO_AUTH_END_POINT: 'https://my4x4academy.com/',
  ECHO_HOST: 'my4x4academy.com',
  ECHO_WS_HOST: 'my4x4academy.com',
  ECHO_WS_PORT: 6001,
  ECHO_PORT: 6001,
  PROD:true,

  //TEST
  //URL_BASE: 'https://kodezilla.com/4x4/services/public/api/',
  //URL_BASE_STORAGE: 'https://kodezilla.com/4x4/services/public',
  //URL_BASE_FRONT: 'https://kodezilla.com/4x4/web',
  //ECHO_BROADCASTER: 'pusher',
  //ECHO_CLUSTER: 'us2',
  //ECHO_KEY: '5c2ebe96a7a06147f0d3',
  //ECHO_AUTH_END_POINT: 'http://kodezilla.com/',
  //ECHO_HOST: 'kodezilla.com',
  //ECHO_WS_HOST: 'kodezilla.com',
  //ECHO_WS_PORT: 6001,
  //ECHO_PORT: 6001,
  //PROD:true,
}
export{ API };

const ECHO_TEST:any = {
  broadcaster: API.ECHO_BROADCASTER,
  cluster: API.ECHO_CLUSTER,
  key: API.ECHO_KEY,
  wsHost: API.ECHO_WS_HOST,
  wsPort: API.ECHO_WS_PORT,
  disableStats: true,
  encrypted: false,
  forceTLS: false,
  enabledTransports: ['ws']
};
export {ECHO_TEST};

const ECHO_PROD:any = {
  broadcaster: API.ECHO_BROADCASTER,
  cluster: API.ECHO_CLUSTER,
  key: API.ECHO_KEY,
  wsHost: API.ECHO_WS_HOST,
  wsPort: API.ECHO_WS_PORT,
  wssPort: API.ECHO_WS_PORT,
  disableStats: true,
  encrypted: true,
  forceTLS: true,
  enabledTransports: ['ws','wss']
};
export {ECHO_PROD};