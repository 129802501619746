import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { egretAnimations } from 'app/shared/animations/egret-animations';
import { BaseService, rangeValidator } from 'app/views/services/base.service';
import { DialogAddSubmissionComponent } from '../dialog-add-submission/dialog-add-submission.component';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-dialog-show-submissions',
  templateUrl: './dialog-show-submissions.component.html',
  styleUrls: ['./dialog-show-submissions.component.css'],
  animations: [egretAnimations]
})
export class DialogShowSubmissionsComponent implements OnInit {

  service:BaseService;
  submissions:any[] = [];
  course:any;
  status_id:number;//Enviados
  gradeForm:FormGroup;
  gradeValues:number[] = [0,0.1,0.2,0.3,0.4,0.5,0.6,0.7,0.8,0.9,1];
  forms:any;
  userInfo:any;
  
  constructor(
    public dialogRef: MatDialogRef<DialogShowSubmissionsComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    injector:Injector
  ) { 
    this.service = new BaseService(injector);
    this.userInfo = this.service.storage.getUserInfo();
  }

  ngOnInit(): void {
    this.selectedIndexChange(0);
  }

  selectedIndexChange(index:number): void {
    switch (index) {
      case 0:
        this.status_id = 1;//Enviados
        break;
      case 1:
        this.status_id = 2;//Calificados
        break;
    }
    this.getSubmissions();
  }

  getSubmissions(){
    this.submissions = [];
    this.service.loader.open();
    this.service.service.getSubmissionsByCourse(this.data.slug,this.status_id).subscribe({
      next: (response) => {
        this.service.loader.close();
        this.course = response.body.data?.course;
        this.submissions = response.body.data?.submissions;
        if (this.status_id == 1) this.createForms(this.submissions);
      },
      error: (err:HttpErrorResponse) => {
        this.service.loader.close();
        this.service.proccessHttpResponse(err);
      }
    });
  }

  createForms(submissions:any[]): void {
    this.forms = {};
    submissions.forEach(
      (s) => {
        let formConfig:any = this.createForm(s);
        this.forms[s.id] = formConfig;
      }
    );
  }
  
  feedbackMaxLength:number = 300;
  feedbackMinLength:number = 5;
  createForm(s:any): any {
    let form: FormGroup = new FormGroup({
      grade_value: new FormControl('', [
        Validators.required,
        Validators.pattern(/^\d+$/), // Solo dígitos sin puntos
        rangeValidator(0, 100),
      ]),
      feedback: new FormControl('', [
        Validators.minLength(this.feedbackMinLength),
        Validators.maxLength(this.feedbackMaxLength),
      ]),
    });
    if (s.grade) {
      this.getControl(s.id,'grade_value').setValue(s.grade.grade);
      this.getControl(s.id,'feedback').setValue(s.grade.feedback);
    }
    let formErrors = {
      grade_value:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'pattern',  message: '* Ingresa sólo números.' },
        { type: 'range',  message: '* Valor incorrecto o fuera de rango.' },
      ],
      feedback:[
        { type: 'required',  message: '* Este campo es requerido.' },
        { type: 'minlength',  message: '* Al menos '+this.feedbackMinLength+' caracteres.' },
        { type: 'maxlength',  message: '* No más de '+this.feedbackMaxLength+' caracteres.' },
      ],
    };
    return {
      form:form,
      formErrors:formErrors
    };
  }

  getFormById(id:number): FormGroup {
    return this.forms[id]?.form;
  }

  setFormById(id:number,form:FormGroup): void {
    this.forms[id].form = form;
  }

  getFormErrorsById(id:number): any {
    return this.forms[id]?.formErrors;
  }

  getControl(id:number,controlName:string): AbstractControl {
    return this.getFormById(id)?.controls[controlName];
  }

  storeSubmissionGrade(s:any): void {
    let data:any = {
      ...this.getFormById(s?.id).value,
      facilitator_id: this.userInfo?.id,
      submission_id: s.id,
    };
    this.service.openConfirm(`¿Deseas asignar / actualizar la calificación de ${(data?.grade_value)}% al participante ${s?.user?.name}?`).subscribe(
      (response) => {
        if (response) {
          this.service.loader.open();
          this.service.service.storeSubmissionGrade(data).subscribe({
            next: (response) => {
              this.service.loader.close();
              this.submissions = this.submissions.filter(sub => sub.id !== s.id);
              this.createForms(this.submissions);
              this.service.openSnackBar(response.body.message);
            },
            error: (err:HttpErrorResponse) => {
              this.service.loader.close();
              this.service.proccessHttpResponse(err);
            }
          });
        }
      }
    );
  }

  /*openDialogAddSubmission(submission:any = null): void {
    this.service.dialog.open(DialogAddSubmissionComponent, {
      width: '50%',
      height: '420px',
      disableClose: false,
      data: {
        topic_id: submission?.topic_id, 
        course_id: this.course.id,
      },
    }).afterClosed().subscribe(
      (response) => {
        console.log(response);
      }
    );
  }*/

}
