import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseService } from 'app/views/services/base.service';

@Component({
  selector: 'app-dialog-add-submission',
  templateUrl: './dialog-add-submission.component.html',
  styleUrls: ['./dialog-add-submission.component.css']
})
export class DialogAddSubmissionComponent implements OnInit {

  service:BaseService;
  topics:any[] = [];
  topic_id:number = 0;
  isFileOver: boolean = false; // Indica si un archivo está sobre el área.
  selectedFile:File=null;

  constructor(
    public dialogRef: MatDialogRef<DialogAddSubmissionComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    injector:Injector
  ) { 
    this.service = new BaseService(injector);
    this.topic_id = this.data.topic_id;
    this.getTopicsByCourse(this.data.slug);
  }

  ngOnInit(): void {
  }

  dismiss(){
    this.dialogRef.close();
  }

  onDragOver(event: DragEvent): void {
    event.preventDefault(); // Permite el "drop".
    this.isFileOver = true; // Activa la clase "over".
  }

  onDragLeave(): void {
    this.isFileOver = false; // Quita la clase "over".
  }

  onDrop(event: DragEvent): void {
    event.preventDefault();
    this.isFileOver = false; // Resetea el estilo tras el drop.
    if (event.dataTransfer?.files) {
      const files = Array.from(event.dataTransfer.files);
      this.handleFiles(files);
    }
  }

  handleFiles(files: File[]): void {
    files.forEach(file => {
      const allowedTypes = ['application/pdf', 'application/vnd.ms-excel', 'application/zip', 'application/x-rar-compressed', 'image/jpeg', 'image/png'];
      const maxSize = 4 * 1024 * 1024; // 4MB

      if (allowedTypes.includes(file.type) && file.size <= maxSize) {
        this.selectedFile = file;
      } else {
        this.service.openDialog(`El tamaño del archivo no debe superar los 4MB, intenta con otro archivo.`);
      }
    });
  }

  getTopicsByCourse(slug:string): void {
    this.service.loader.open();
    this.service.service.getTopicsByCourse(slug).subscribe({
      next: (response) => {
        this.service.loader.close();
        this.topics = response.body.data;//Obtiene los temas completados del usuario
        if (this.topics.length <= 0) {
          this.service.openDialog(`Necesitas completar al menos 1 tema para poder enviar un trabajo.`).subscribe(
            () => { this.dialogRef.close(); }
          );
        }
      },
      error: (err:HttpErrorResponse) => {
        this.service.loader.close();
        this.service.proccessHttpResponse(err);
      }
    });
  }

  onTopicChange(topic_id:number): void {
    this.topic_id = topic_id;
  }

  submit(): void {
    this.service.openConfirm(`¿Deseas enviar tu trabajo al facilitador? Sólo pordrás enviarlo una vez.`).subscribe(
      (response) => {
        if (response) {
          const formData:FormData = new FormData();
          formData.append('file',this.selectedFile);
          formData.append('topic_id',this.topic_id.toString());
          formData.append('course_id',this.getCurrentTopic()?.module?.course?.id);
          this.service.loader.open();
          this.service.service.storeSubmission(formData).subscribe({
            next: (response) => {
              this.service.loader.close();
              this.service.openDialog(response.body.message);
              if (response.body.success) {
                this.dismiss();
              }
            },
            error: (err:HttpErrorResponse) => {
              this.service.loader.close();
              this.service.proccessHttpResponse(err);
            }
          });
        }
      }
    )
  }

  getCurrentTopic(): any {
    return this.topics.find(f => f.id == this.topic_id);
  }
}
