<p mat-dialog-title>Adjuntar trabajo:</p>
<mat-dialog-content>
  <div fxFlex="100" fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="10px">
    <mat-form-field appearance="outline" class="full-width">
      <mat-label>Elige un tema:</mat-label>
      <mat-select (selectionChange)="onTopicChange($event.value)" [(ngModel)]="topic_id">
        <mat-option *ngFor="let t of topics" [value]="t?.id">{{ t?.topic }}</mat-option>
      </mat-select>
      <mat-hint>{{ getCurrentTopic()?.module?.module }}</mat-hint>
    </mat-form-field>
    <div 
      fxFlex="100"
      class="dropzone" 
      [class.over]="isFileOver"
      (dragover)="onDragOver($event)" 
      (drop)="onDrop($event)">
      <p class="text-purple" *ngIf="selectedFile">	
        <b>Archivo seleccionado:</b> "{{ selectedFile?.name }}"<br/>
        <b>Tamaño:</b> {{ (selectedFile?.size / (1024 * 1024)).toFixed(2) }} MB
      </p>
      <p>Arrastra y suelta tus archivos aquí</p>
      <p>
        <small>Tipos de archivo permitidos: .pdf, .docx, .xlsx, .pptx, .zip, .rar</small>
        <br/>
        <small>Tamaño máximo del archivo: 4 MB (MegaBytes).</small>
      </p>
    </div>

  </div>
</mat-dialog-content>
<mat-dialog-actions align="center" style="overflow-x: clip;">
  <button class="btn-purple" mat-button [mat-dialog-close]="false">
    <mat-label class="m-1">Cancelar</mat-label>
    <mat-icon aria-hidden="false">cancel_outline</mat-icon>
  </button>
  <button class="btn-green" mat-button (click)="submit()" [disabled]="!selectedFile || !topic_id">
    <mat-label class="m-1">Enviar</mat-label>
    <mat-icon aria-hidden="false">check_circle_outline</mat-icon>
  </button>
</mat-dialog-actions>