import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogModuleComponent } from 'app/shared/components/dialog-module/dialog-module.component';
import { DialogComponent } from 'app/shared/components/dialog/dialog.component';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { DialogConfirmComponent } from 'app/views/dialogs/dialog-confirm/dialog-confirm.component';
import { AdminService } from 'app/views/services/admin.service';
import { PaginatorService } from 'app/views/services/paginator.service';
import { RoleService } from 'app/views/services/role.service';
import { StorageService } from 'app/views/services/storage.service';
import { Module } from "app/views/shared/interfaces";
@Component({
  selector: 'app-admin-module',
  templateUrl: './admin-module.component.html',
  styleUrls: ['./admin-module.component.css']
})
export class AdminModuleComponent implements OnInit {
  row:Module;
  public courseId:number=0;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  userInfo:any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: AdminService,
    private router: Router,
    public dialog: MatDialog,
    private loader: AppLoaderService,
    private paginatorService: PaginatorService,
    private storage:StorageService,
    public role:RoleService
  ) { }

  async ngOnInit() {   
    this.userInfo = this.storage.getUserInfo();
    if (this.role.isAdmin()) {
      this.displayedColumns = ['module','description','Temas','activeLabel','Borrar','Editar'];
    }
    if (this.role.isInstructor()) {
      this.displayedColumns = ['module','description','Temas'];
    }
    this.courseId =0; 
    await this.activatedRoute.paramMap.subscribe(async params => { 
      this.courseId = await Number(params.get('id'));
      if (this.courseId>0) {; 
        this.dataSource.data = await []; 
        await this.getModule(this.courseId); 
      } else {
        this.service.openDialog('No fue posible obtener la información del módulo.');
        this.router.navigateByUrl('/dashboard/cmsCourses');
      }
    });
  }
  ngAfterViewInit() {
    this.paginator._intl = this.paginatorService.translatePaginator(this.paginator._intl);
    this.dataSource.paginator = this.paginator;
    //console.log(this.dataSource.data);
  }
  async getModule(courseId:number){
    this.loader.open('Cargando Modulos');
    const loader = this.loader.getDialog();
    this.service.getModelesXIdCatCurse(courseId).subscribe(
      async (data) => { 
          this.dataSource.data = await data.body.content;
          this.loader.close();
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        switch (http.status) {
          case 401:
            this.service.openDialog('Operación no permitida');
            this.service.clearUserInfo();
            this.service.navigateTo('/signin');
            break;
          default:
            this.service.openDialog('Error con el servicio.');
        }
      }
    );
  }
  public onClickNuevo() {
    let item:any = {
      id : 0,
      module : '',
      description : '',
      active : 1,
      courseId : this.courseId
    }
     this.row = item;
    this.openDialog();
  }
  saveModule(data){
    this.loader.open();
    this.service.createModule(data).subscribe(
      (data:any) => {
        this.loader.close();
        this.dialogCongfirm("Módulo creado correctamente");
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }
  onClickEdit(item:any){
    this.row =item;
    this.openDialog();
  }
  public openDialog() {
    this.dialog.open(DialogModuleComponent, {
      disableClose: true,
      width: '50%',
      data:this.row
    })
    .afterClosed()
    .subscribe((data: any) => {
        if (data != null) {
          if(this.row.id==0){
            this.saveModule(data);
          }else{
            
            this.updateModule(data);
          }
        } 
      });
  }
  updateModule(data){
    this.loader.open();
    this.service.updateModule(data).subscribe(
      (data:any) => {
        this.loader.close();
        this.dialogCongfirm("Módulo actualizado correctamente");
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }
  deleteModule(data){
    this.row = data;
    this.loader.open();
    this.service.deleteModule(data).subscribe(
      (response:any) => {
        this.loader.close();
        if (String(response.content).includes('SQLSTATE[23000]')) {
          this.dialogCongfirm("El modulo que intenta eliminar está asociado a uno o varios temas y no es posible eliminar.");
          return;
        } 
        this.dialogCongfirm("Módulo eliminado correctamente");
      },
      (http: HttpErrorResponse) => {
        this.loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }
  goToCatCourse(){
    this.router.navigate(['/dashboard/cmsCourses']);
  }
  goToTopics(moduloId){
    this.router.navigate(['/dashboard/cmsTopic',moduloId,this.courseId]);
  }
  onClickDelete(item:any){
    this.row =item;
    this.openDialogConfirm("Eliminar","¿Desea eliminar módulo?",true);
  }
  public openDialogConfirm(title:string,content:string,viewButton_:boolean) {
    this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        title: title,
        content: content,
        at: '',
        viewButton:viewButton_
      }
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
        if (confirmado) {
          this.deleteModule(this.row);
        } 
      });
  }
  openConfirm(message:string){
    return this.dialog.open(DialogConfirmComponent,{
      data:{message:message,confirm:false},
      autoFocus: false,
      disableClose: true
    }).afterClosed().toPromise();
  }
  
  dialogCongfirm(message:string){
        this.openConfirm(message)
        .then(
          (response) => {
            this.getModule(this.courseId);
          }
        )
        .catch(
          (error) => {
            this.service.openDialog(`Error al: ${message}`)
          }
        );
  }
}
