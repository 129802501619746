import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnInit, QueryList, ViewChildren } from '@angular/core';
import { MatSelectionList } from '@angular/material/list';
import { egretAnimations } from '../../../../shared/animations/egret-animations';
import { API } from 'app/views/shared/api';
import FileSaver from 'file-saver';
import { BaseService } from 'app/views/services/base.service';
import { DialogAddSubmissionComponent } from 'app/views/dialogs/dialog-add-submission/dialog-add-submission.component';
import { DialogShowSubmissionsComponent } from 'app/views/dialogs/dialog-show-submissions/dialog-show-submissions.component';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.css'],
  animations: egretAnimations
})
export class CourseDetailsComponent extends BaseService implements OnInit {

  panelOpenState = false;
  imageUrl:string = '';
  modules:Array<any>;
  selectedResources:any = undefined;
  course:any;
  resource:any;
  courseId:any;
  arrayResources:Array<any> = new Array();
  @ViewChildren('_modules') private components:QueryList<MatSelectionList>;
  layoutConf:any;
  constructor(injector:Injector) { super(injector); }

  userInfo:any;
  ngOnInit(): void {
    this.layoutConf = this.layout.layoutConf;
    this.activatedRoute.paramMap.subscribe(params => {
      const courseId_ = String(params.get('id')).trim();
      this.courseId = courseId_;
      if (courseId_) {
        this.userInfo = this.service.getUserInfo();
        if (this.role.isClient()) {
          this.getCourseByUser(courseId_);
        } else {
          this.getCourse(courseId_);
        }
      } else {
        this.service.openDialog('No fue posible obtener la información del curso.');
        this.router.navigateByUrl('/dashboard/cursos');
      }
    });
  }

  showTutorial(){
    let tutorialIsDisplayed = localStorage.getItem('tutorialCourseDetailIsDisplayed');
    if(this.role.isClient() && tutorialIsDisplayed!='1'){
      localStorage.setItem('tutorialCourseDetailIsDisplayed','1');
      setTimeout(this.service.showTutorialDetailCourseClient, 1000);
    }
  }

  selectedTopic:string = '';
  selectedTopicDescripcion:string = '';
  selectedModuleDescripcion:string = ''
  setSelectedResources(resources:any){
    this.selectedResources = resources;
  }

  //Usuarios no logueados
  getCourse(courseId:string,selectFirst:boolean = true){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getCourse(courseId).subscribe(
      async (data) => {
        loader.close();
        if (data.status == 200){
          let content:any = data.body.content;
          await this.setCourseInformation(content,selectFirst,false);
        } else {
          this.service.openDialog(`No fue posible obtener la información del curso, intente más tarde o verifique con el área de soporte.`).subscribe(
            () => {this.service.goTo('/dashboard/cursos')}
          );
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }
  //Usuarios logueados
  getCourseByUser(courseId:string,selectFirst:boolean = true){
    this.loader.open();
    const loader = this.loader.getDialog();
    this.service.getCourseByUser(courseId).subscribe(
      async (data) => {
        loader.close();
        if (data.status == 200){
          let content:any = data.body.content;
          if(content?.course?.requireRenew==1){
            this.service.openDialog(`Este curso requiere una renovación para acceder al contenido.`).subscribe(
              () => {this.service.goTo(`/studyPlan/${courseId}`);}
            );
          }else{
            await this.setCourseInformation(content,selectFirst,true);
          }
        } else {
          this.service.openDialog(`No fue posible obtener la información del curso, intente más tarde o verifique con el área de soporte.`).subscribe(
            () => {this.service.goTo('/dashboard/cursos')}
          );
        }
      }, 
      (http: HttpErrorResponse) => {
        loader.close();
        this.service.processHttpResponse(http);
      }
    );
  }

  async setCourseInformation(content:any,selectFirst:boolean,isLogged:boolean):Promise<void> {
    this.course = content.course;
    this.imageUrl = this.course.imageUrl != "" ?  API.URL_BASE_STORAGE + this.course.imageUrl : 'assets/images/entrepeneurs/profile.png'
    let date:string = this.course.createdAt;
    this.course.createdAt = date.trim().replace(/ /g,"T");
    this.modules = content.modules;
    await this.processModules(this.modules);
    //Seleccionar primer tema
    if (selectFirst){
      this.setLastCompletedTopic(this.modules);
    }
    //Definir el ultimo módulo para aumento de nivel al completar todos los módulo
    if (isLogged) {
      this.setLastTopic(this.modules);
    }
  }

  selectFirstModuleTopic() {
    if (this.modules) {
      if(this.modules.length > 0){
        let module = this.modules[0];
        let topics = module?.topics;
        if(topics && topics.length > 0){
          let topic = topics[0];
          this.showResources(topic);
        }
      }
    }
  }  

  setFirstVideo() {
    if (this.currentResources) {
      this.currentVideo = null;
      let found:boolean = false;
      for (let i = 0; i < this.currentResources.length && !found; i++) {
        let resource:any = this.currentResources[i];
        if (this.isVideo(resource)) { 
          this.currentVideo = resource;
          this.currentVideo.resourceUrl = null;
          this.currentVideo.resourceUrl = this.getName(resource);
          let fileName:String = resource.fileName;
          this.currentVideo.videoName = fileName.substr(0,fileName.length-4);//mostrar nombre sin la extensión
          this.currentVideo.ended = false;
          found = true;
        }
      }
    }
  }

  setLastCompletedTopic(modules:any[]){
    let lastTopic:any;
    let moduleIndex:number;
    let index:number = 0;
    if (modules.length > 0) {
      lastTopic = modules[0]?.topics[0];
      modules.forEach(module => {
        if(module.topics.length){
          module.topics.forEach(topic => {
            if(topic.completed){
              lastTopic = topic;
              moduleIndex = index;
            }
          });
        }
        index++;
      });
      this.showResources(lastTopic,moduleIndex);
    }
  }

  lastTopic:any;
  setLastTopic(modules:any[]){
    if (modules.length > 0) {
      let lastModule:any = modules[modules.length - 1];
      let topics:any[] = (lastModule.hasOwnProperty('topics'))?lastModule.topics:[];
      if (topics.length > 0) {
        this.lastTopic = topics[topics.length - 1];
      }
    }
  }

  resourcesHasFiles:boolean = false;
  currentResourcesHasFiles():void {
    let hasFiles:boolean = false;
    if (this.currentResources.length > 0) {
      this.currentResources.forEach(
        (res) => {
          if (this.isPDF(res) || this.isImage(res)) {
            hasFiles = true;
          }
          return;
        }
      )
    }
    this.resourcesHasFiles = hasFiles;
  }

  currentVideo:any = null;
  currentResources:any[] = [];
  showResources(topic:any,index:number = 0,topicId:string = ''){
    let topicSelected = document.getElementById(topicId) as HTMLElement;
    //Validacion para saber si el item ya ha sido seleccionado
    if (topicSelected) {
      let selected:string = topicSelected.getAttribute('aria-selected');
      topicSelected.setAttribute('aria-selected','true');
      if (selected === 'true') {
        return;//Si el item ya ha sido seleccionado no realizamos ninguna accion
      }
    }
    //
    this.currentTopicId = Number(topic?.id);
    this.currentModuleIndex = Number(index);
    this.selectedTopic = topic?.topic;
    this.selectedTopicDescripcion = topic?.description;
    this.selectedModuleDescripcion = this.modules[this.currentModuleIndex].description;
    this.arrayResources = topic?.resources || [];
    this.currentResources = topic?.resources || [];
    this.setFirstVideo();
    this.currentResourcesHasFiles();
    if (this.components) {
      let modules:any[] = this.components.toArray();
      //Seleccionamos el modulo actual
      let selectionModule:MatSelectionList = modules[index];
      if (selectionModule) {
        let selected:any[] = selectionModule.selectedOptions.selected;
        //selectionModule.deselectAll();
        //des-seleccionar los temas en todos los modulos
        modules.forEach((m:MatSelectionList)=>m.deselectAll());
        //selecciona el ultimo tema elegido (clickeado)
        selectionModule.selectedOptions.select(selected[selected.length-1]);
      }
    }
    setTimeout(() => {
      //console.log(document.scrollingElement.scrollTop);
      window.scrollTo(0,0);
      document.scrollingElement.scrollTo(0,0);
      document.body.scrollTo(0,0);
      document.getElementById('rightside-content-hold').scrollTo(0,0);
    }, 1);
  }

  public isImage(item: any){
    let name = String(item.fileName);
    let fileExt = this.getFileExtension(name);
    let isImageFile:boolean = false;
    switch (fileExt) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        isImageFile = true;  
        break;
    
      default:
        isImageFile = false;
        break;
    }
    return isImageFile;
  }

  public isPDF(item: any){
    let name = String(item.fileName);
    return this.getFileExtension(name) == 'pdf';
  }

  public isVideo(item: any){
    let name = String(item.fileName);
    let isVideoFile:boolean = false;
    let fileExt = this.getFileExtension(name);
    switch (fileExt) {
      case 'mp4':
      case 'avi':
      case 'webm':
        isVideoFile = true;  
        break;
    
      default:
        isVideoFile = false;
        break;
    }

    return isVideoFile;
  }

  public getTypeVideo(fileName: any){
    return 'video/'+ this.getFileExtension(fileName);
  }

  public isOfficeFile(item: any){
    let name = String(item.fileName);
    let isOfficeFile_:boolean = false;
    let fileExt = this.getFileExtension(name);
    switch (fileExt) {
      case 'doc':
      case 'docx':
      case 'xls':
      case 'xlsx':
      case 'pptx':
      case 'ppt':
        isOfficeFile_ = true;  
        break;
    
      default:
        isOfficeFile_ = false;
        break;
    }
    return isOfficeFile_;
  }

  public getIconFile(item : any ){
    let name = String(item.fileName);
    let fileExt = this.getFileExtension(name);
    let nameIcon:string = '';
    switch (fileExt) {
      case 'doc':
      case 'docx':
        nameIcon = 'assets/images/word_file.png';  
        break;
      case 'xls':
      case 'xlsx':
        nameIcon = 'assets/images/excel_file.png';  
        break;
      case 'pptx':
      case 'ppt':
        nameIcon = 'assets/images/ppoint_file.png';  
        break;
      default:
        nameIcon = '';
        break;
    }
    return nameIcon;
  }

  public getFileExtension(filename){
    var ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : ext[1];
  }
  
  public downloadFile(item : any){
    FileSaver.saveAs(this.getName(item), item.fileName);
  }

  public getName(item: any){
    return API.URL_BASE_STORAGE + String(item.resourseUrl);
  }

  //Funcion para habilitar o deshabilitar temas vistos
  public async processModules(modules: Array<any>){
    try {
      if (modules) {
        //Ordenamos los topics y topics_resources
        modules.forEach(
          (module) => {
            if (module.topics) {
              module.topics = this.service.sortByOrder(module.topics);
              //Ordenamos los recursos por cada tema
              let topics:any[] = module.topics;
              topics.forEach(
                (topic) => {
                  if (topic?.resources) {
                    topic.resources = this.service.sortByOrder(topic?.resources);
                  }
                }
              );
            }
          }
        );
        //
        let found:boolean = false;
        for (let i = 0; i < modules.length && !found; i++) { 
          let topics:any[] = (modules[i].topics)?modules[i].topics:[];
          for (let j = 0; j < topics.length && !found; j++) {
            let topic:any = topics[j];
            if (topic.completed == 0){
              topic.completed = 1;
              found = true;
            }
          }
        }
      }
      this.showTutorial();  
    } catch (error) {
      console.log(error);
      this.service.openDialog(`Ocurrió un error al procesar los módulos, contacte con el área de soporte`);
    }
  }

  //Verificar si todos los recursos del tema han sido vistos
  public async checkResourcesCompleted(resources:Array<any>) {
    let allWatched:boolean = true;
    for (let index = 0; index < resources.length; index++) {
      const recurso = resources[index];
      if(String(recurso.completed_) == "0"){
        allWatched = false;
      }
    }
    if (resources.length == 0) {
      allWatched = false;
    }
    return allWatched;
  }

  public async vidEnded(resource : any ){
    resource.completed_ = 1;
    //Buscando el topic para actualizar sus recursos
    let topic = this.modules.find(modulo => String(modulo.id) == String(resource.module_id));
    if(topic){
      this.setSelectedResources(topic.resources);
    }
    await this.processModules(this.modules); 
  }

  public openDialogCompras(){
    this.router.navigate(['/dashboard/course/buy/',this.courseId,{isMentoria: true}]);
  }

  goToCalendar(course){
    this.router.navigate([this.CALENDAR_ROUTE,course.courseSlug]);
  }

  goToListPupil(course){
    this.router.navigate(['/dashboard/cmsAlumnos',course.id]);
  }

  currentModuleIndex:number = 0;
  currentTopicId:number = 1;
  videoEnded(resource:any) {
    let topicId:number = Number(resource.topic_id);
    let topic = this.getTopicById(topicId);
    let nextTopic = this.getNextTopic(topicId,this.currentModuleIndex);
    // verificamos si el video se ha visto por primera vez y además el tema no se ha completado
    if (this.currentVideo.hasOwnProperty('ended') && this.currentVideo.ended == false && Number(resource.completed_) != 1) {
      let message:string = (nextTopic)
        ?
        `¡Felicidades has terminado el tema "${topic?.topic}" a continuación el tema "${nextTopic?.topic}"!`
        :
        `¡Felicidades has terminado el tema "${topic?.topic}"!`
        ;
      this.currentVideo.ended = true;
      if (this.role.isClient()) {
        topic.lastTopic = (this.lastTopic.id === topic.id)?true:false;
        this.loader.open();
        this.service.registerTopicSeen(topic,this.course)
        .then(
          (response:any) => {
            this.loader.close();
            let topicUser:any = response.content;
            message += (topic.lastTopic == true)?` y has aumentado tu nivel.`:``;
            let messageResult = (topicUser.completed == 0 && topicUser.simulator == 1)
                                ? `¡Necesitas completar el modelo de simulación para desbloquear los siguientes temas`
                                :message
                                ;
            this.service.openDialog(messageResult).subscribe(
              ()=> {
                this.service.dialog.closeAll(); 
                this.getCourseByUser(this.courseId,false)
              }
            );  
        })
        .catch(
          (error : any) => {
            this.loader.close();
            this.service.openDialog("Ha ocurrido un error con el servicio");
          }
        );
      }   
    }
  }

  getTopicById(topicId):any {
    let topicFound:any = undefined;
    if (this.modules) {
      this.modules.forEach(
        (m) => {
          let topics:any[] = m.topics;
          let topic = topics.find((t) => Number(t.id) === Number(topicId));
          if (topic) {
            topicFound = topic;
            return;
          }
        }
      )
    }
    return topicFound;
  }

  getNextTopic(topicId,moduleIndex) {
    let topicFound:any = undefined;
    if (this.modules) {
      let m:any = this.modules[moduleIndex];
      let topics:any[] = m.topics;
      let indexOf = topics.findIndex((t) => Number(t.id) === Number(topicId));
      let nextTopic = topics[indexOf+1];
      if (nextTopic && indexOf != -1) {
        topicFound = nextTopic;
      } 
    }
    return topicFound;
  }

  openDialogAddSubmission(): void {
    this.dialog.open(DialogAddSubmissionComponent, {
      width: '50%',
      height: '420px',
      disableClose: false,
      data: {
        topic_id: this.currentTopicId, 
        slug: this.courseId
      },
    }).afterClosed().subscribe(
      (response) => {}
    );
  }

  openDialogShowSubmissions(): void {
    this.dialog.open(DialogShowSubmissionsComponent, {
      width: '920px',
      height: '500px',
      disableClose: false,
      data: {
        slug: this.courseId
      },
    }).afterClosed().subscribe(
      (response) => {}
    );
  }

}
