<div class="container-fluid">
	<div layout="row" layout-xs="column" fxLayoutAlign="space-between none" >
        <div flex="5">

        </div>
        <div flex="85" fxLayoutAlign="space-between none" style="width: 100%;margin-top: auto;margin-left: 10px;">
            <h4>
                Administración de curso
            </h4>
        </div>
        <div flex="15" *ngIf="role.isAdmin()">
            <button mat-button class="btn-purple float-right" (click)="onClickNuevo()" *ngIf="rolVer">
                <mat-label>Nuevo curso</mat-label>
            </button>
        </div>
    </div>
	<div fxFlexAlign="center" fxLayout="column" fxLayout.md="row">
		<!---->
		<div class="mat-elevation-z4" fxLayout="row" fxLayoutAlign="space-around strech" fxLayout.lt-md="column" fxLayout.md="column" fxFlex.md="100">

			<div class="p-2 bg-light-gray" fxLayout.md="column" fxFlex="100">
				<div class="example-container mat-elevation-z8">
					<mat-table #table [dataSource]="dataSource">
						<ng-container matColumnDef="course">
							<mat-header-cell class="m-1" *matHeaderCellDef> Curso</mat-header-cell>
							<mat-cell class="m-1" *matCellDef="let element"> 
							  <span class="mobile-label">Curso:</span>
							  {{element.course}} 
							</mat-cell>
						  </ng-container>
						  <ng-container matColumnDef="name" [style.display]="role.isInstructor() ? 'inline' : 'none'">
							<mat-header-cell class="m-1" *matHeaderCellDef> Facilitador </mat-header-cell>
							<mat-cell class="m-1" *matCellDef="let element"> 
							  <span class="mobile-label">Facilitador:</span>
							  {{element.name}} 
							</mat-cell>
						  </ng-container>
						  <ng-container matColumnDef="salePrice" [style.display]="role.isAdmin() ? 'inline' : 'none'">
							<mat-header-cell *matHeaderCellDef class="centrado"> Precio </mat-header-cell>
							<mat-cell class="centrado" *matCellDef="let element"> 
							  <span class="mobile-label">Precio:</span>
							  {{element.salePrice}} 
							</mat-cell>
						  </ng-container>
						  <ng-container matColumnDef="courseLeve">
							<mat-header-cell class="m-1" *matHeaderCellDef> Tipo </mat-header-cell>
							<mat-cell class="m-1" *matCellDef="let element"> 
							  <span class="mobile-label">Tipo:</span>
							  {{element.contentType}} 
							</mat-cell>
						  </ng-container>
						  <ng-container matColumnDef="applyPromotion" [style.display]="role.isAdmin() ? 'inline' : 'none'">
							<mat-header-cell *matHeaderCellDef> Promoción</mat-header-cell>
							<mat-cell *matCellDef="let element"> 
							  <span class="mobile-label">Promoción:</span>
							  {{element.applyPromotionLabel}} 
							</mat-cell>
						  </ng-container>
						  <ng-container matColumnDef="inscribed">
							<mat-header-cell *matHeaderCellDef> Inscritos</mat-header-cell>
							<mat-cell *matCellDef="let element"> 
							  <span class="mobile-label">Inscritos:</span>
							  <button mat-mini-fab color="primary" focusable="false" (click)="goToListPupil(element.id)">
								<mat-icon>group</mat-icon>
							</button>
							</mat-cell>
						  </ng-container>
						  
							<ng-container matColumnDef="activeLabel">
								<mat-header-cell *matHeaderCellDef> Activo</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
									<span class="mobile-label">Activo:</span>
									{{element.activeLabel}} 
								</mat-cell>
						  </ng-container>
						  
							<ng-container matColumnDef="Borrar" [style.display]="role.isAdmin() ? 'inline' : 'none'">
								<mat-header-cell *matHeaderCellDef>Activo</mat-header-cell>
								<mat-cell *matCellDef="let element; let i = index;"> 
									<span class="mobile-label">Activar/Desactivar</span>
									<mat-checkbox style="margin-left: 5%;" color="primary" (change)= "onClickDelete(element,$event,i)" [checked]="element.active == 1"></mat-checkbox>
								</mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="Editar" [style.display]="role.isAdmin() ? 'inline' : 'none'">
								<mat-header-cell *matHeaderCellDef> Editar</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
									<span class="mobile-label">Editar:</span>
										<button mat-mini-fab color="primary" focusable="false" (click)="onClickEdit(element)">
										<mat-icon>edit</mat-icon>
									</button>
								</mat-cell>
						  </ng-container>

						  <ng-container matColumnDef="Modules">
								<mat-header-cell *matHeaderCellDef> Módulos</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
									<span class="mobile-label">Módulo:</span>
										<button mat-mini-fab color="primary" focusable="false" (click)="goToModule(element.id)">
										<mat-icon>source</mat-icon>
									</button>
								</mat-cell>
						  </ng-container>

							<ng-container matColumnDef="paymentMethods" [style.display]="role.isAdmin() ? 'inline' : 'none'">
								<mat-header-cell *matHeaderCellDef> Métodos de pago</mat-header-cell>
								<mat-cell *matCellDef="let element"> 
									<span class="mobile-label">Métodos de pago:</span>
									<button mat-mini-fab color="primary" [disabled]="element.contentTypeId === 3" (click)="goToPaymentMethods(element.id)">
										<mat-icon>credit_card</mat-icon>
									</button>
								</mat-cell>
							</ng-container>
						  
							<ng-container matColumnDef="actions">
								<mat-header-cell *matHeaderCellDef>Acciones</mat-header-cell>
								<mat-cell *matCellDef="let row"> 
									<div fxFlex="100" fxLayoutAlign="center center" fxLayoutGap="10px">
										<span class="mobile-label">Acciones:</span>
										<button mat-mini-fab color="primary" focusable="false" matTooltip="Ver detalles del curso" [routerLink]="[COURSES_AUTH_ROUTE,row?.course_slug]">
											<mat-icon>description</mat-icon>
										</button>
										<button mat-mini-fab color="primary" focusable="false" matTooltip="Trabajos por calificar" (click)="openDialogShowSubmissions(row)">
											<mat-icon>done_all</mat-icon>
										</button>
									</div>
								</mat-cell>
						  </ng-container>

						  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
						  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
					</mat-table>
					<mat-paginator [length]="5" [pageSize]="10" showFirstLastButtons></mat-paginator>
				</div>
			</div>
		</div>
	</div>
</div>
<hr/>
